import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useResponsive } from '@trustyou/shared';
import { Box, Stack } from '@trustyou/ui';

import { ExistingResponse } from './non-editable-response/existing-response';
import { NonRespondableMessage } from './non-editable-response/non-respondable-message';
import { ResponseMetadata } from './non-editable-response/response-metadata';
import { ReadOnlyRoleAlert } from './response-form/read-only-role-alert';
import { ResponseForm } from './response-form/response-form';
import { ResponseTabs } from './response-tabs';

import { useReview } from '../../../hooks';
import { useResponseAIPermission } from '../../../hooks/permissions/use-response-ai-permission';
import useSaveResponsePermission from '../../../hooks/permissions/use-save-response-permission';
import { ResponseTabsEnum } from '../../../types';
import { isDeletionConfirmed, isDeletionPendingOrRequested } from '../../../utils/review';
import { UsedAIGuides } from '../../response-ai/used-ai-guides';
import { DeletedReviewAlert, MarkedAsDeletedReviewAlert } from '../../review-deletion';
import {
  InappropriateProcessingReviewAlert,
  MarkedAsInappropriateReviewAlert,
} from '../../survey-moderation';
import { InappropriateReviewAlert } from '../../survey-moderation/inappropriate-review-alert';

export function ResponseSide() {
  const { isMobile } = useResponsive();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const hasResponseAIPermission = useResponseAIPermission();
  const isSaveResponseAllowed = useSaveResponsePermission(); // Read-only role

  const { response, review, deletion, moderation } = reviewRoot ?? {};
  const { score, title, text, respondable } = review ?? {};

  const hasExistingResponse = Boolean(response);
  const hasScoreTitleOrText = Boolean(score !== undefined || title?.length || text?.length);
  const isMarkedAsDeleted = isDeletionPendingOrRequested(deletion);
  const isDeleted = isDeletionConfirmed(deletion);
  const isModerationRequested = moderation?.status === 'requested';
  const isModerationPropagating = moderation?.status === 'propagating';
  const isModerationApproved = moderation?.status === 'approved';

  const shouldShowResponseForm =
    respondable &&
    !hasExistingResponse &&
    !isMarkedAsDeleted &&
    !isDeleted &&
    !isModerationRequested &&
    !isModerationApproved &&
    !isModerationPropagating;
  const shouldShowResponseAI = hasResponseAIPermission && hasScoreTitleOrText;
  const shouldShowMetaData = !shouldShowResponseForm;

  const [currentTab, setCurrentTab] = useState<ResponseTabsEnum>(
    shouldShowResponseAI ? ResponseTabsEnum.RESPONSE_AI : ResponseTabsEnum.TEMPLATE
  );
  const [guidesUsed, setGuidesUsed] = useState<string[] | null>();

  if (!isSaveResponseAllowed) {
    return (
      <Stack justifyContent="center" height="100%" paddingX={3}>
        <ReadOnlyRoleAlert />
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        ...(isMobile && {
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }),
      }}
    >
      {shouldShowMetaData && <ResponseMetadata />}
      {isMarkedAsDeleted && <MarkedAsDeletedReviewAlert />}
      {isDeleted && <DeletedReviewAlert deletionRequestDate={deletion?.requested_at ?? ''} />}
      {isModerationRequested && <MarkedAsInappropriateReviewAlert />}
      {isModerationApproved && <InappropriateReviewAlert />}
      {isModerationPropagating && <InappropriateProcessingReviewAlert />}
      {guidesUsed?.length ? <UsedAIGuides guides={guidesUsed} /> : null}
      {!respondable && <NonRespondableMessage />}
      {respondable && hasExistingResponse && <ExistingResponse />}
      {shouldShowResponseForm && (
        <>
          <ResponseTabs
            currentTab={currentTab}
            shouldShowResponseAI={shouldShowResponseAI}
            onChange={(value) => setCurrentTab(value)}
          />
          <ResponseForm formType={currentTab} onGenerateResponseAI={setGuidesUsed} />
        </>
      )}
    </Box>
  );
}

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import {
  useChangelingStore,
  useMembershipStore,
  useOrganizationLogo,
  useRouteUtils,
} from '@trustyou/shared';
import { UserMenu } from '@trustyou/ui';

import useCustomerLogout from '../../../service/hooks/useCustomerLogout';
import { OrganizationsMenu } from '../OrganizationsMenu/OrganizationsMenu';

export const UserMenuWrapper = () => {
  const { navigateWithBase } = useRouteUtils();
  const { accessTokenPayload } = useOidcAccessToken();
  const { membership } = useMembershipStore();
  const { isChangeling, userInfo } = useChangelingStore();
  const { data: organizationLogo } = useOrganizationLogo();

  const navToProfile = () => navigateWithBase('settings/user');

  const isProfileShown = !!membership?.id;

  const customerLogout = useCustomerLogout();

  return (
    <UserMenu
      name={isChangeling ? userInfo?.name : accessTokenPayload.name}
      email={isChangeling ? userInfo?.email : accessTokenPayload.email}
      logo={organizationLogo?.logo_uri}
      onLogout={customerLogout}
      navToProfile={isProfileShown ? navToProfile : undefined}
    >
      {(onClick) => <OrganizationsMenu onClick={onClick} />}
    </UserMenu>
  );
};

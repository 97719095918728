import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { faMagnifyingGlass } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Spacer, StyledFontAwesomeIcon } from '@trustyou/ui';

export const CustomToolbar = ({ hideColumns }: { hideColumns?: boolean }) => {
  return (
    <GridToolbarContainer sx={{ alignItems: 'start', padding: 1 }}>
      {!hideColumns && <GridToolbarColumnsButton />}
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Spacer />
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <StyledFontAwesomeIcon
              icon={faMagnifyingGlass}
              size="lg"
              sx={{ marginRight: 0.5, color: 'text.secondary' }}
            />
          ),
        }}
        sx={{ width: 220 }}
      />
    </GridToolbarContainer>
  );
};

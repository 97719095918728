import type { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import {
  getAllowedNodes,
  routePermissions,
  useActiveDeepNode,
  useActiveNode,
  useMembershipStore,
  usePermissionStore,
  useResponsive,
  useSidebarStore,
} from '@trustyou/shared';
import { Box, CollapsibleDrawer } from '@trustyou/ui';

import { combinedNodes, footerNodes, nodes } from '../../../constants/sideBarNodes';
import useAlertChangeling from '../../../service/hooks/changeling/useAlertChangeling';
import { MobileLayout } from '../../Mobile/Layout/Layout';
import { TopBar } from '../TopBar/TopBar';
import styles from './styles';

export const NavigationSidebar = ({ children }: PropsWithChildren) => {
  const { JSX: changelingAlert } = useAlertChangeling();
  const { isMobile } = useResponsive();
  const { isCollapsed } = useSidebarStore();

  const { permissions } = usePermissionStore();
  const { membership } = useMembershipStore();
  const membershipIdPath = membership?.id ? `/org/${membership.id}` : '';

  const activeNode = useActiveNode(nodes(membershipIdPath), footerNodes(membershipIdPath));
  const activeDeepNode = useActiveDeepNode(combinedNodes(membershipIdPath));
  const allowedNodes = getAllowedNodes(nodes(membershipIdPath), routePermissions, permissions);
  const allowedFooterNodes = getAllowedNodes(
    footerNodes(membershipIdPath),
    routePermissions,
    permissions
  );

  if (isMobile)
    return (
      <MobileLayout
        header={activeDeepNode && { id: activeDeepNode.id, message: activeDeepNode.name }}
        nodes={allowedNodes.filter((node) => node.isVisibleOnMobile)}
        footerNodes={allowedFooterNodes.filter((node) => node.isVisibleOnMobile)}
        isRootNode={activeNode?.id === activeDeepNode?.id}
      >
        {changelingAlert}
        {children}
      </MobileLayout>
    );

  return (
    <Box>
      {changelingAlert}
      <Box
        sx={{
          ...styles.container,
          ...(changelingAlert && { 'MuiDrawer-paper': styles.changelingMode }),
        }}
      >
        <CollapsibleDrawer primaryNodes={allowedNodes} secondaryNodes={allowedFooterNodes} />
        <Box
          sx={{
            ...styles.rightContainer,
            ...(isCollapsed ? styles.collapsed : styles.expanded),
            ...(changelingAlert ? styles.changelingMode : {}),
          }}
        >
          <TopBar
            displayLogo={false}
            header={activeNode && { id: activeNode.id, message: activeNode.name }}
            sx={styles.topBar}
          />
          <Box component="main" sx={styles.mainContainer}>
            {children}
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

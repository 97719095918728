import DOMPurify from 'dompurify';

import type {
  BooleanPrimitive_Input,
  CreateQuestionIn,
  NPSPrimitive_Input,
  ScalePrimitive_Input,
  SelectPrimitive_Input,
} from '../client';
import type { Color } from '../components/feedback/types';
import type { PrimitiveTypeMap, SurveyQuestionEditorProps } from '../types/question';

function getPrimitive(type: string, originalData: SurveyQuestionEditorProps) {
  const primitives = {
    number: {
      type: 'number',
      answer_type: 'number',
      validations:
        originalData.minNumber !== undefined && originalData.maxNumber !== undefined
          ? [
              {
                name: 'min_value',
                value: originalData.minNumber !== undefined ? originalData.minNumber : 0,
              },
              {
                name: 'max_value',
                value: originalData.maxNumber !== undefined ? originalData.maxNumber : 0,
              },
            ]
          : [],
      repr: 'short_text',
    },
    short_text: {
      type: 'text',
      answer_type: 'text',
      validations:
        originalData.textMaxCharacters !== undefined
          ? [
              {
                name: 'max_length',
                value:
                  originalData.textMaxCharacters !== undefined ? originalData.textMaxCharacters : 0,
              },
            ]
          : [],
      repr: 'short_text',
    },
    long_text: {
      type: 'text',
      answer_type: 'text',
      validations:
        originalData.textMaxCharacters !== undefined
          ? [
              {
                name: 'max_length',
                value:
                  originalData.textMaxCharacters !== undefined ? originalData.textMaxCharacters : 0,
              },
            ]
          : [],
      repr: 'long_text',
    },
    email: {
      type: 'text',
      answer_type: 'text',
      validations: [{ name: 'email', value: true }],
      repr: 'email',
    },
    phone: {
      type: 'text',
      answer_type: 'text',
      validations: [{ name: 'phone_number', value: true }],
      repr: 'phone',
    },
    dropdown_single: {
      type: 'select',
      answer_type: 'option',
      validations: [],
      options: [],
      repr: 'dropdown',
    },
    dropdown_multiple: {
      type: 'select',
      answer_type: 'option',
      validations: [],
      options: [],
      repr: 'dropdown',
    },
    radio_group: {
      type: 'select',
      answer_type: 'option',
      validations: [],
      options: [],
      repr: 'radio_group',
    },
    checkbox_group: {
      type: 'select',
      answer_type: 'option',
      validations: [],
      options: [],
      repr: 'checkbox_group',
    },
    'day-month-year': {
      type: 'date',
      answer_type: 'date',
      validations: [],
      repr: 'datepicker',
    },
    'month-year': {
      type: 'date',
      answer_type: 'date',
      validations: [],
      repr: 'year_month_picker',
    },
    button_group: {
      type: 'boolean',
      answer_type: 'boolean',
      validations: [],
      options: [],
      repr: 'button_group',
    },
    checkbox: {
      type: 'boolean',
      answer_type: 'boolean',
      validations: [],
      repr: 'checkbox',
    },
    'numbers-one-to-five': {
      type: 'scale',
      answer_type: 'number',
      validations: [],
      options: [],
      repr: 'scale',
    },
    'numbers-one-to-ten': {
      type: 'scale',
      answer_type: 'number',
      validations: [],
      options: [],
      repr: 'scale',
    },
    nps: {
      type: 'scale',
      answer_type: 'number',
      validations: [],
      options: [],
      repr: 'scale',
    },
  } as PrimitiveTypeMap;

  return primitives[type] || {};
}

export function convertQuestionEditorData(originalData: SurveyQuestionEditorProps) {
  const COLOR_CONVERT: Record<Color, string> = {
    error: '#E53935',
    warning: '#EF6C00',
    success: '#388E3C',
  };
  // Initialize the target structure
  const convertedData: CreateQuestionIn = {
    internal_name: {},
    default_language: originalData.defaultLanguage || 'en',
    title: {},
    description: {},
    primitive: getPrimitive(
      originalData.questionTypeVariation || originalData.questionType,
      originalData
    ),
    handling_type: 'custom',
    not_applicable_label: {},
  };

  if (originalData.ratingOptions?.length > 0) {
    (convertedData.primitive as ScalePrimitive_Input | NPSPrimitive_Input).options =
      originalData.ratingOptions.map((option) => ({
        value: option.value,
        color: COLOR_CONVERT[option.color as Color],
        label: {},
      }));
  } else if (originalData.selectOptions?.length > 0) {
    (convertedData.primitive as SelectPrimitive_Input).options = originalData.selectOptions.map(
      (option) => ({
        value: option.value,
        label: {},
      })
    );
  } else if (originalData.binaryOptions?.length > 0) {
    convertedData.title[originalData.defaultLanguage] = DOMPurify.sanitize(
      originalData.binaryOptions[0].value as unknown as string
    );

    if (originalData.binaryOptions.length > 1) {
      (convertedData.primitive as BooleanPrimitive_Input).options = originalData.binaryOptions.map(
        (option) => ({
          value: option.value,
          label: {},
        })
      );
    }
  }

  if (originalData.savedTranslations) {
    for (const [lang, translation] of Object.entries(originalData.savedTranslations)) {
      convertedData.internal_name[lang] = translation.internalName || '';
      convertedData.title[lang] = translation.questionText || '';
      convertedData.description[lang] = translation.additionalText || '';
      if (convertedData.not_applicable_label) {
        convertedData.not_applicable_label[lang] = translation.notApplicable || 'Not Applicable';
      }

      if (translation.ratingOptions) {
        translation.ratingOptions.forEach((transOption, index) => {
          const ratingPrimitive = convertedData.primitive as
            | ScalePrimitive_Input
            | NPSPrimitive_Input;
          if (ratingPrimitive && ratingPrimitive.options && ratingPrimitive.options[index]) {
            ratingPrimitive.options[index].label[lang] = transOption.label;
          }
        });
      }

      if (translation.selectOptions) {
        translation.selectOptions.forEach((transOption, index) => {
          const selectPrimitive = convertedData.primitive as SelectPrimitive_Input;
          if (selectPrimitive && selectPrimitive.options && selectPrimitive.options[index]) {
            selectPrimitive.options[index].label[lang] = transOption.label;
          }
        });
      }

      if (translation.binaryOptions && originalData.binaryOptions?.length === 1) {
        convertedData.title[lang] = DOMPurify.sanitize(translation.binaryOptions[0].label);
      } else if (translation.binaryOptions) {
        translation.binaryOptions.forEach((transOption, index) => {
          const binaryPrimitive = convertedData.primitive as SelectPrimitive_Input;
          if (binaryPrimitive && binaryPrimitive.options && binaryPrimitive.options[index]) {
            binaryPrimitive.options[index].label[lang] = transOption.label;
          }
        });
      }
    }
  }

  return convertedData;
}

import { type ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  type BasicEntity,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_ASSIGNABLE_ENTITIES,
  type SelectionMode,
  searchPlaceholders,
  useInfiniteAssignableEntities,
  useScopeStore,
} from '@trustyou/shared';
import { Box, ListItemText } from '@trustyou/ui';

import { Selector } from '../../Selector/Selector';

interface SubscriptionEntitySelectorProps {
  subscriptionId: string;
  initialSelectedEntities?: Entity[] | BasicEntity[];
  isListDisabled?: boolean;
  selectedEntitiesHeader?: ReactNode;
  hideSelectResults?: boolean;
  mode?: SelectionMode;
}

export const SubscriptionEntitySelector = ({
  subscriptionId,
  initialSelectedEntities = [],
  isListDisabled = false,
  selectedEntitiesHeader,
  hideSelectResults,
  mode,
}: SubscriptionEntitySelectorProps) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { selectedEntities, setSelectedEntities } = useScopeStore();
  const [searchKey, setSearchKey] = useState('');

  const { data, fetchNextPage, hasNextPage, isPending, isFetchingNextPage } =
    useInfiniteAssignableEntities(subscriptionId, DEFAULT_PAGE_SIZE, DEFAULT_SORT_KEY, searchKey);
  const [entities, setEntities] = useState<BasicEntity[]>([]);

  const isLoading = isPending || isFetchingNextPage;

  useEffect(() => {
    setSelectedEntities(initialSelectedEntities);
    return () => {
      queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ASSIGNABLE_ENTITIES] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchedEntities = data?.pages.map((page) => page.data).flat() || [];
    setEntities(fetchedEntities);
    const totalSubscriptionEntities = data?.pages[0].pagination.total;
    const isOnlyOneEntity =
      initialSelectedEntities.length === 0 &&
      selectedEntities.length === 0 &&
      fetchedEntities.length === 1 &&
      totalSubscriptionEntities === 1;
    if (isOnlyOneEntity) {
      setSelectedEntities(fetchedEntities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ASSIGNABLE_ENTITIES] });
  };

  const renderRowContent = (entity: BasicEntity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  return (
    <Box>
      <Selector
        searchPlaceholder={intl.formatMessage(searchPlaceholders.searchEntities)}
        isLoading={isLoading}
        items={entities}
        onSelectItems={setSelectedEntities}
        onSearch={onSearch}
        renderRowContent={renderRowContent}
        selectedItems={selectedEntities}
        selectedHeaderTitle={
          selectedEntitiesHeader ?? (
            <FormattedMessage
              id="organization.users.selectedEntities"
              defaultMessage="Selected entities ({count})"
              values={{ count: selectedEntities.length }}
            />
          )
        }
        onFetchNextPage={onFetchNextPage}
        isListDisabled={isListDisabled}
        hideSelectResults={hideSelectResults}
        mode={mode}
      />
    </Box>
  );
};

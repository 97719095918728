import { FormattedMessage, useIntl } from 'react-intl';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { amber, blue, deepPurple, indigo, orange, purple, teal } from '@mui/material/colors';
import {
  faBuildings,
  faChartLine,
  faClipboardListCheck,
  faGauge,
  faMessageDots,
  faMoneyCheckDollar,
  faUsers,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type NodeType,
  isTemporaryHidden,
  navigationNodes,
  useMembershipStore,
  usePermissionStore,
  useResponsive,
  useUserOrganization,
} from '@trustyou/shared';
import { Box, Grid, Skeleton, Typography } from '@trustyou/ui';

import HelpLinks from './HelpLinks';
import { NavCard } from './NavCard/NavCard';

import styles from './styles';

function useHomeLinks() {
  const intl = useIntl();
  const { membership } = useMembershipStore();
  const { hasRoutePermissions } = usePermissionStore();
  const { isMobile } = useResponsive();

  const homeLinks: NodeType[] = [
    {
      id: 'your-dashboard',
      icon: faGauge,
      iconColor: deepPurple[300],
      name: intl.formatMessage(navigationNodes.dashboard),
      path: `/org/${membership?.id}/analytics`,
    },
    {
      id: 'inbox',
      icon: faMessageDots,
      iconColor: purple[300],
      name: intl.formatMessage(navigationNodes.inbox),
      path: `/org/${membership?.id}/inbox`,
      isVisibleOnMobile: true,
    },
    {
      id: 'survey-manager',
      icon: faClipboardListCheck,
      iconColor: orange[500],
      name: intl.formatMessage(navigationNodes.surveyManager),
      path: `/org/${membership?.id}/survey-manager`,
    },
    {
      id: 'survey-insights',
      icon: faChartLine,
      iconColor: amber[500],
      name: intl.formatMessage(navigationNodes.surveyInsights),
      path: `/org/${membership?.id}/survey-insights`,
      isHidden: isTemporaryHidden('CXP-customers-survey-insights'),
    },
    {
      id: 'your-entities',
      icon: faBuildings,
      iconColor: teal[300],
      name: intl.formatMessage(navigationNodes.entities),
      path: `/org/${membership?.id}/organization/entities`,
      isVisibleOnMobile: true,
    },
    {
      id: 'organization-members',
      icon: faUsers,
      iconColor: blue[300],
      name: intl.formatMessage(navigationNodes.users),
      path: `/org/${membership?.id}/organization/users`,
      isVisibleOnMobile: true,
    },
    {
      id: 'subscriptions',
      icon: faMoneyCheckDollar,
      iconColor: indigo[300],
      name: intl.formatMessage(navigationNodes.subscriptions),
      path: `/org/${membership?.id}/subscriptions`,
      isHidden: isTemporaryHidden('CXP-customers-subscription'),
    },
    // {
    //   id: 'import_entities',
    //   icon: faArrowUpFromLine,
    //   iconColor: orange[500],
    //   name: intl.formatMessage({ id: 'import_entities', defaultMessage: 'Import entities' }),
    //   path: '../',
    //   isHidden: true,
    // },
  ];

  const isVisible = (node: NodeType) =>
    hasRoutePermissions(node.path ?? '') && (node.isVisibleOnMobile || !isMobile) && !node.isHidden;

  return homeLinks.filter(isVisible);
}

export function Home() {
  const { accessTokenPayload } = useOidcAccessToken();
  const { data, isPending } = useUserOrganization();
  const homeLinks = useHomeLinks();

  return (
    <Box sx={{ flexGrow: 1 }} padding={{ xs: 2, md: 0 }}>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={{ xs: 2.5, sm: 3 }}>
            <Grid item xs={12}>
              <Box sx={styles.userCard}>
                <Typography variant="h5">
                  <FormattedMessage
                    id="home.helloUser"
                    defaultMessage="Hello, {user}"
                    values={{ user: accessTokenPayload.name }}
                  />
                </Typography>
                <Box>
                  <Typography variant="body2">
                    <FormattedMessage
                      id="home.clickOnTheLinksBelowToCheckOutYourOrganization"
                      defaultMessage="Click on the links below to check out your organization"
                    />
                  </Typography>
                  {isPending ? <Skeleton /> : <Typography variant="body2">{data?.name}</Typography>}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 2, sm: 3 }}>
                {homeLinks.map((navLink) => (
                  <Grid key={navLink.id} item xs={12} sm={6}>
                    <NavCard {...navLink} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <HelpLinks />
        </Grid>
      </Grid>
    </Box>
  );
}

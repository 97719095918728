import { FormattedMessage, useIntl } from 'react-intl';

import { faCheckDouble } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faUserTie } from '@trustyou/fortawesome/pro-solid-svg-icons';

import { commonMessages } from '../../constants/messages';
import type { SpecificStampProps } from '../../types';
import { Stamp } from './stamp';
import { StampPair } from './stamp-pair';

export function ConfirmedResponseStampPair({ date, author }: SpecificStampProps) {
  const intl = useIntl();

  return (
    <StampPair>
      <Stamp
        status={
          <FormattedMessage
            id="inbox.response.status.responseConfirmed"
            defaultMessage="Response confirmed: {processedDate}"
            values={{ processedDate: date }}
          />
        }
        tooltip={intl.formatMessage({
          id: 'inbox.reply.status.responseConfirmed.tooltip',
          defaultMessage: 'We confirmed the response was published on the source',
        })}
        icon={faCheckDouble}
        sx={{ color: (theme) => theme.palette.success.main }}
      />
      {author && (
        <Stamp
          status={intl.formatMessage(commonMessages.byUsername, { username: author })}
          icon={faUserTie}
        />
      )}
    </StampPair>
  );
}

import { type SyntheticEvent, useState } from 'react';
import { type Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { SxProps, Theme } from '@mui/system';
import { USER_STATUSES } from '@trustyou/shared';
import { Autocomplete, FormControl, FormHelperText, Stack, TextField } from '@trustyou/ui';
import { chain, debounce } from 'lodash';

import { RecipientOption } from './recipient-option';
import { RenderTags } from './render-tags';

import { recipientsSelectorMessages } from '../../constants/messages';
import { useRecipients } from '../../service/hooks';
import type { Recipient } from '../../types/recipient';
import { FormControlLabel } from '../form-control-label/form-control-label';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  sx?: SxProps<Theme>;
  defaultRecipient?: Recipient;
};

export const RecipientSelector = ({ control, sx, defaultRecipient }: Props) => {
  const intl = useIntl();
  const [searchKey, setSearchKey] = useState('');
  const { data, isFetching } = useRecipients(searchKey);
  const isError = !!control._formState.errors.recipients;

  const options = chain(data || [])
    .filter({ status: USER_STATUSES.ACTIVE })
    .uniqBy('email')
    .thru((result) => {
      if (defaultRecipient && defaultRecipient.email.includes(searchKey)) {
        const exists = result.some(({ id }) => id === defaultRecipient.id);
        if (!exists) {
          result.push(defaultRecipient);
        }
      }
      return result;
    })
    .value();

  const onInputChangeDebounced = debounce((_: SyntheticEvent, value: string) => {
    setSearchKey(value);
  }, 500);

  return (
    <FormControl sx={sx}>
      <Controller
        control={control}
        name="recipients"
        render={({ field: { value, onChange } }) => (
          <Stack>
            <FormControlLabel required>
              {intl.formatMessage(recipientsSelectorMessages.recipients)}
            </FormControlLabel>
            <Autocomplete
              size="small"
              filterOptions={(options) => options}
              onInputChange={onInputChangeDebounced}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.email}
              openOnFocus={true}
              multiple
              limitTags={1}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
              noOptionsText={intl.formatMessage(recipientsSelectorMessages.notFound)}
              clearOnBlur
              handleHomeEndKeys
              id="recipient-selector"
              options={options}
              renderOption={RecipientOption}
              renderTags={RenderTags}
              renderInput={(params) => (
                <TextField
                  placeholder={intl.formatMessage(recipientsSelectorMessages.placeholder)}
                  error={isError}
                  {...params}
                />
              )}
              loadingText={intl.formatMessage(recipientsSelectorMessages.loading)}
              loading={isFetching}
              value={value}
              defaultValue={control._defaultValues.recipients as Recipient[]}
            />
            {isError && (
              <FormHelperText error>
                {intl.formatMessage(recipientsSelectorMessages.required)}
              </FormHelperText>
            )}
          </Stack>
        )}
      />
    </FormControl>
  );
};

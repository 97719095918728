import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { isTemporaryHidden, useShowBenchmarks, useShowSurveyInsights } from '@trustyou/shared';
import { FormControl, MenuItem, Select, Stack, Typography } from '@trustyou/ui';

import { Footer } from './footer';

import { FormControlLabel } from '../../../components';
import {
  commonMessages,
  newReportWizardMessages,
  reportNameMessages,
} from '../../../constants/messages';
import { useDashboardIds } from '../../../service/hooks';
import { useNewReportStore } from '../../../store';
import styles from './styles';

type ReportsList = {
  id: string;
  label: {
    id: string;
    defaultMessage: string;
  };
};

export const SettingsStep = () => {
  const isShowBenchmarksAllowed = useShowBenchmarks();
  const isShowSurveyInsightsAllowed = useShowSurveyInsights();
  const { newReport, nextStep, setContentId } = useNewReportStore();
  const { data } = useDashboardIds();

  useEffect(() => {
    if (data && !newReport?.contentId) {
      setContentId(data.review_dashboard_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const reportsList = useMemo(() => {
    let list: ReportsList[] = [];
    if (data) {
      list = [
        { id: data.review_dashboard_id, label: reportNameMessages.reviews },
        { id: data.sentiment_dashboard_id, label: reportNameMessages.sentiment },
        { id: data.competitor_dashboard_id, label: reportNameMessages.competitors },
      ];
      if (isShowBenchmarksAllowed && data.benchmark_dashboard_id) {
        list.push({ id: data.benchmark_dashboard_id, label: reportNameMessages.benchmarks });
      }
      if (isShowSurveyInsightsAllowed && data.survey_insights_id) {
        list.push({ id: data.survey_insights_id, label: reportNameMessages.surveyReviews });
      }
      if (isShowSurveyInsightsAllowed && data.survey_stats_id) {
        list.push({ id: data.survey_stats_id, label: reportNameMessages.surveyStats });
      }
    }
    if (!isTemporaryHidden('CXP-customers-inbox-reports')) {
      list.push({ id: 'reviews_inbox', label: reportNameMessages.reviewsInbox });
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Stack sx={styles.stepBody} direction="row" gap={5}>
        <Stack spacing={2} sx={{ width: '50%' }}>
          <Typography variant="body1" width="90%">
            <FormattedMessage {...newReportWizardMessages.settingsInfoOne} />
          </Typography>
          <Typography variant="body1" width="90%">
            <FormattedMessage {...newReportWizardMessages.settingsInfoTwo} />
          </Typography>
        </Stack>
        <Stack width="50%" gap={1}>
          <FormControl>
            <FormControlLabel required>
              <FormattedMessage {...newReportWizardMessages.content} />
            </FormControlLabel>
            <Select
              data-testid="report-content-select"
              value={newReport?.contentId || ''}
              onChange={(event) => {
                setContentId(event.target.value);
              }}
              size="small"
              sx={styles.dashboardSelect}
            >
              {reportsList.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <FormattedMessage {...option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage {...commonMessages.format} />
          </Typography>
          <Typography variant="subtitle1">Excel</Typography>
        </Stack>
      </Stack>
      <Footer onClickNext={nextStep} />
    </>
  );
};

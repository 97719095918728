import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { commonFields } from '@trustyou/shared';
import { Box, Button, ContentPane, Link, Stack, Typography, snackbar } from '@trustyou/ui';

import { AIEntityGuides } from './ai-entity-guides';
import { ToneComparisonDialog } from './tone-comparison-dialog';

import { useEditProfile, useResponseAIProfile } from '../../../../hooks/api-query';
import { useLeaveDialog } from '../../../../hooks/leave-dialog/use-leave-dialog';
import { useLanguage } from '../../../../hooks/use-language';
import {
  type Language,
  ReplyLengthLabels,
  type ResponseAISettingsFormData,
} from '../../../../types';
import { getFormattedProfileData } from '../../../../utils/settings';
import { DropdownFormField } from '../../../dropdowns/dropdown-form-field';
import { SettingsSection } from '../../../settings-section/settings-section';

export function ResponseAISettingsForm() {
  const intl = useIntl();
  const { toneOfVoiceOptions, replyLengthOptions } = useResponseAISettingsOptions();
  const { languagesWithAuto } = useLanguage();
  const responseAIProfile = useResponseAIProfile();
  const editProfile = useEditProfile();

  const [isToneComparisonOpen, setToneComparisonOpen] = useState(false);

  const methods = useForm<ResponseAISettingsFormData>({
    values: {
      default_tone_of_voice: responseAIProfile.data?.tone_of_voice ?? 'formal',
      default_reply_length: responseAIProfile.data?.reply_length ?? ReplyLengthLabels.Auto,
      default_translation_language: responseAIProfile.data?.translation_language ?? 'AUTO',
      guides: responseAIProfile.data?.guides ?? [],
      default_reply_language: 'AUTO' as Language | 'AUTO',
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = methods;
  const { renderLeaveDialog, canContinue } = useLeaveDialog({ showDialog: isDirty });

  const onSubmit = handleSubmit((data) => {
    const formattedProfileData = getFormattedProfileData(data);
    editProfile.mutate(formattedProfileData, {
      onSuccess: () => {
        snackbar.success(intl.formatMessage(commonFields.changesSaved));
      },
    });
  });

  const onCancel = async () => {
    if (await canContinue()) {
      reset();
    }
  };

  return (
    <ContentPane bodyStyles={{ justifyContent: 'space-between' }}>
      <FormProvider {...methods}>
        <Typography variant="h6">
          <FormattedMessage id="inbox.responseAI" defaultMessage="ResponseAI" />
        </Typography>
        <Stack gap={5} mt={3}>
          <SettingsSection
            title={
              <FormattedMessage id="inbox.settings.voice.title" defaultMessage="Tone of voice" />
            }
            description={
              <Box component="span" sx={{ display: 'flex', gap: 0.5 }}>
                <FormattedMessage
                  id="inbox.settings.voice.description"
                  defaultMessage="Select the style responseAI should use."
                />
                <Link component="button" onClick={() => setToneComparisonOpen(true)}>
                  <FormattedMessage
                    id="inbox.settings.voice.details"
                    defaultMessage=" Click to see a comparison"
                  />
                </Link>
              </Box>
            }
          >
            <DropdownFormField
              name="default_tone_of_voice"
              label={
                <FormattedMessage id="inbox.settings.voice.default" defaultMessage="Default tone" />
              }
              options={toneOfVoiceOptions}
            />
          </SettingsSection>
          <SettingsSection
            title={
              <FormattedMessage id="inbox.settings.length.title" defaultMessage="Response length" />
            }
            description={
              <FormattedMessage
                id="inbox.settings.length.description"
                defaultMessage="When left on “Auto”, responseAI will try to match the length of the response to that of the guest review."
              />
            }
          >
            <DropdownFormField
              name="default_reply_length"
              options={replyLengthOptions}
              label={
                <FormattedMessage
                  id="inbox.settings.length.default"
                  defaultMessage="Default response length"
                />
              }
            />
          </SettingsSection>
          <SettingsSection
            title={
              <FormattedMessage
                id="inbox.settings.translation.title"
                defaultMessage="Translation"
              />
            }
            description={
              <FormattedMessage
                id="inbox.settings.translation.description"
                defaultMessage={`The language which will be used In case you decide to translate the generated response. If set to "Auto" the default app language will be used`}
              />
            }
          >
            <DropdownFormField
              name="default_translation_language"
              options={languagesWithAuto}
              label={
                <FormattedMessage
                  id="inbox.settings.translation.default"
                  defaultMessage="Default translation language"
                />
              }
            />
          </SettingsSection>
          <Box paddingTop={5}>
            <AIEntityGuides />
          </Box>
          <Stack direction="row" gap={2} justifyContent="flex-end">
            <Button
              disabled={!isDirty}
              onClick={onCancel}
              data-testid="inbox_settings_cancel"
              sx={{ alignSelf: 'end' }}
            >
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              onClick={onSubmit}
              variant="contained"
              data-testid="inbox_settings_save"
              sx={{ alignSelf: 'end' }}
            >
              <FormattedMessage id="inbox.action.save-changes" defaultMessage="Save changes" />
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
      {renderLeaveDialog({
        title: intl.formatMessage({
          id: 'inbox.settings.dialog.header',
          defaultMessage: 'Discard changes?',
        }),
        content: intl.formatMessage({
          id: 'inbox.settings.dialog.message',
          defaultMessage: 'You have unsaved changes on this page. If you leave, they will be lost.',
        }),
      })}
      <ToneComparisonDialog
        isOpen={isToneComparisonOpen}
        onClose={() => setToneComparisonOpen(false)}
      />
    </ContentPane>
  );
}

function useResponseAISettingsOptions() {
  const intl = useIntl();

  const toneOfVoiceOptions = [
    {
      value: 'formal',
      label: intl.formatMessage({
        id: 'inbox.settings.voice.labelFormal',
        defaultMessage: 'Formal',
      }),
    },
    {
      value: 'casual',
      label: intl.formatMessage({
        id: 'inbox.settings.voice.labelCasual',
        defaultMessage: 'Casual',
      }),
    },
  ];

  const replyLengthOptions = [
    {
      value: 'auto',
      label: intl.formatMessage({
        id: 'inbox.settings.length.labelAuto',
        defaultMessage: 'Auto',
      }),
    },
    {
      value: 'detailed',
      label: intl.formatMessage({
        id: 'inbox.settings.length.labelDetailed',
        defaultMessage: 'Detailed',
      }),
    },
    {
      value: 'brief',
      label: intl.formatMessage({
        id: 'inbox.settings.length.labelBrief',
        defaultMessage: 'Brief',
      }),
    },
  ];

  return { toneOfVoiceOptions, replyLengthOptions };
}

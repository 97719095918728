import { FormattedMessage } from 'react-intl';

import type { IconButtonProps } from '@mui/material';
import { faArrowLeft, faBars } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  AppBar,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Toolbar,
  Typography,
} from '@trustyou/ui';

export type TopBarProps = {
  header?: {
    id: string;
    message: string;
  };
  isRoot?: boolean;
  onClick: IconButtonProps['onClick'];
};

export function MobileTopBar({ header, isRoot, onClick }: TopBarProps) {
  return (
    <AppBar
      color="inherit"
      // FIXME: The `elevation={1}` prop should have the same styles as the design in Figma: https://www.figma.com/design/eJxzZj6f8PbZchTvVYwhYc/CXP-Inbox?node-id=3455-103061&m=dev
      // Maybe we need to update the design token for the boxShadow.
      // elevation={1}
      sx={{
        boxShadow:
          '0px 1px 3px 0px rgba(69, 90, 100, 0.06), 0px 1px 1px 0px rgba(69, 90, 100, 0.07), 0px 2px 1px -1px rgba(69, 90, 100, 0.10)',
      }}
    >
      <Toolbar sx={{ height: 64 }}>
        <IconButton color="inherit" sx={{ marginInlineEnd: 2 }} onClick={onClick}>
          <Icon icon={isRoot ? faBars : faArrowLeft} />
        </IconButton>
        {header && (
          <Typography component="h1" variant="h6">
            <FormattedMessage id={header.id} defaultMessage={header.message} />
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

import { type MouseEventHandler, type ReactNode, useState } from 'react';
import { useController } from 'react-hook-form';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { grey } from '@mui/material/colors';
import {
  Button,
  ButtonGroup as MuiButtonGroup,
  StyledFontAwesomeIcon,
  Typography,
} from '@trustyou/ui';

import type { SelectQuestionProps } from '../question-types/select/select-question';
import type { Color } from '../types';

const color = {
  background: {
    unselected: grey[50],
    unselectedHover: grey[200],
    selected: grey[300],
    selectedHover: grey[400],
  },
  icon: {
    unselected: grey[700],
    selected: grey[900],
  },
};

type CustomButtonProps = {
  label: ReactNode;
  isSelected: boolean;
  onClick: MouseEventHandler;
  icon?: IconDefinition;
  iconColor?: Color | string | null;
};

function CustomButton({ label, isSelected, onClick, icon, iconColor }: CustomButtonProps) {
  return (
    <Button
      onClick={onClick}
      sx={{
        flexDirection: 'column',
        gap: 1,
        padding: icon ? 2 : 1,
        backgroundColor: color.background.unselected,
        '&:hover': {
          backgroundColor: color.background.unselectedHover,
        },
        ...(isSelected && {
          backgroundColor: color.background.selected,
          '&:hover': {
            backgroundColor: color.background.selectedHover,
          },
        }),
      }}
    >
      {icon && (
        <StyledFontAwesomeIcon
          icon={icon}
          size="xl"
          sx={{
            color: iconColor
              ? iconColor?.startsWith('#')
                ? iconColor
                : `${iconColor}.main`
              : 'text.secondary',
          }}
        />
      )}
      <Typography variant="body2" fontWeight={isSelected ? 500 : 400}>
        {label}
      </Typography>
    </Button>
  );
}

export type ButtonGroupWithIconsProps = SelectQuestionProps;

export function ButtonGroup({ name, control, options }: ButtonGroupWithIconsProps) {
  const { field } = useController({ name, control });
  const [selectedOption, setSelectedOption] = useState(field.value ?? '');

  const handleOptionChange = (value: string | number | boolean) => {
    if (typeof value === 'boolean') field.onChange(value);
    else field.onChange([value]);
    setSelectedOption(value);
  };

  return (
    <MuiButtonGroup
      variant="outlined"
      aria-label="button group"
      color="inherit"
      fullWidth
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          gap: theme.spacing(2),
          '& .MuiButtonGroup-grouped:not(:last-of-type), & .MuiButtonGroup-grouped:not(:first-of-type)':
            {
              borderRadius: 'inherit',
              borderRightColor: 'inherit',
              margin: 0,
            },
        },
      })}
    >
      {options.map((option) => (
        <CustomButton
          key={option.value.toString()}
          label={option.label}
          icon={option.icon as IconDefinition}
          isSelected={
            option.value === (Array.isArray(selectedOption) ? selectedOption[0] : selectedOption)
          }
          iconColor={option.color}
          onClick={() => handleOptionChange(option.value as string)}
        />
      ))}
    </MuiButtonGroup>
  );
}

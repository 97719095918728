import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { SelectChangeEvent } from '@mui/material';
import { faSliders } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { EXAMPLE_VIEW, useInboxStore, useMembershipStore, useResponsive } from '@trustyou/shared';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  StyledFontAwesomeIcon,
} from '@trustyou/ui';

import { ViewItem } from './view-item';

import { useFetchViews } from '../../hooks';
import { usePredefinedViews } from '../../hooks/use-predefined-views';
import type { View } from '../../types';

const VIEWS_SELECT_WIDTH_PX = 260;
const REPORT_SELECT_WIDTH_PX = 320;

export type ViewsDropdownProps = {
  handleManage?: () => void;
  isReports?: boolean;
};

export const ViewsDropdown = ({ handleManage, isReports }: ViewsDropdownProps) => {
  const intl = useIntl();
  const { isPhone } = useResponsive();
  const { allReviewsView, lastSevenDaysView } = usePredefinedViews();
  const currentView = useInboxStore((state) => state.currentView);
  const { data: views = [] } = useFetchViews();
  const [isOpen, setOpen] = useState(false);
  const setCurrentView = useInboxStore((state) => state.setCurrentView);
  const { membership } = useMembershipStore();

  const handleFilter = (view: View) => {
    if (view.filters && !view.filters?.relative_timerange && !view.filters?.preset_timerange) {
      view.filters.relative_timerange = 'all-time';
    }
    setCurrentView({ ...view, membership_id: membership?.id });
  };

  const normalizeFilters = (search_terms: string[], view: View) => {
    const allReviewsViewWithSearchTerms = {
      ...allReviewsView,
      filters: { ...allReviewsView.filters, search_terms },
    };
    const customViewWithChangedFilters = {
      ...view,
      filters: {
        ...view?.filters,
        search_terms,
      },
    };
    const normalizedView = view ? customViewWithChangedFilters : allReviewsViewWithSearchTerms;
    return normalizedView;
  };

  const handleApplyFilters = (newView: View) => {
    handleFilter(normalizeFilters(currentView.filters?.search_terms ?? [], newView));
  };

  const handleSelectView = (event: SelectChangeEvent<unknown>) => {
    const viewName = event.target.value;
    if (viewName === allReviewsView.label) {
      handleApplyFilters(allReviewsView);
      return;
    }
    if (viewName === lastSevenDaysView.label) {
      handleApplyFilters(lastSevenDaysView);
      return;
    }
    const viewToApply = views.find((view) => view.label === viewName);
    if (!viewToApply) return;
    handleApplyFilters(viewToApply);
  };

  return (
    <FormControl
      size="small"
      fullWidth={isPhone}
      sx={{
        minWidth: isReports ? REPORT_SELECT_WIDTH_PX : VIEWS_SELECT_WIDTH_PX,
      }}
    >
      {isReports && (
        <InputLabel>
          <FormattedMessage id="inbox.common.view" defaultMessage="View" />
        </InputLabel>
      )}
      <Select
        id="view-select"
        label={isReports && <FormattedMessage id="inbox.common.view" defaultMessage="View" />}
        defaultValue={lastSevenDaysView.label}
        value={
          currentView.label ||
          intl.formatMessage({
            id: 'inbox.views.dropdown.filtered-results',
            defaultMessage: 'Filtered results',
          })
        }
        renderValue={(value) => value}
        sx={{ ...(!currentView.label && { color: (theme) => theme.palette.text.secondary }) }}
        open={isOpen}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={handleSelectView}
        MenuProps={{ PaperProps: { sx: { width: VIEWS_SELECT_WIDTH_PX } } }}
      >
        <MenuItem
          data-gtm-class="inbox_select_view"
          data-gtm-id="inbox_all_reviews_view"
          value={allReviewsView.label}
        >
          <ViewItem view={allReviewsView} />
        </MenuItem>
        <MenuItem
          data-gtm-class="inbox_select_view"
          data-gtm-id="inbox_last_seven_days_view"
          value={lastSevenDaysView.label}
        >
          <ViewItem view={lastSevenDaysView} />
        </MenuItem>
        <MenuItem
          value={intl.formatMessage({
            id: 'inbox.views.dropdown.filtered-results',
            defaultMessage: 'Filtered results',
          })}
          sx={{ display: 'none' }}
        />
        <Divider />
        {views.length === 0 && (
          <MenuItem
            data-gtm-class="inbox_select_view"
            data-gtm-id={`${JSON.stringify(EXAMPLE_VIEW.filters)}`}
            value={EXAMPLE_VIEW.label}
          >
            <ViewItem view={EXAMPLE_VIEW} />
          </MenuItem>
        )}
        {views.map((view, index) => (
          <MenuItem
            data-gtm-class="inbox_select_view"
            data-gtm-id={`${JSON.stringify(view.filters)}`}
            key={index}
            value={view.label}
          >
            <ViewItem view={view} />
          </MenuItem>
        ))}
        <Divider />
        {handleManage && (
          <MenuItem
            onClick={handleManage}
            data-gtm-class="inbox_manage_view"
            data-gtm-id="inbox_manage_view_button"
          >
            <Button
              startIcon={<StyledFontAwesomeIcon icon={faSliders} style={{ fontSize: 'inherit' }} />}
              sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
            >
              <FormattedMessage id="inbox.manage-views" defaultMessage="Manage Views" />
            </Button>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

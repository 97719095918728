import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Box, FormControlLabel, Radio, Stack, TextField, snackbar } from '@trustyou/ui';

import { ResponseFieldActionWrapper } from './response-field-action-wrapper';

import { type LanguageSource, useLanguage } from '../../../../hooks';
import { useStore } from '../../../../store/store';
import type {
  LanguageItem,
  ResponseFormSchema,
  ResponseTranslationOption,
} from '../../../../types';
import { DropDownChip, type Option } from '../../../dropdowns/dropdown-chip';
import { ResponseAIRatingDesktop } from '../../../response-ai/response-ai-rating/response-ai-rating-desktop';

const translationOptionMap: Record<ResponseTextFieldId, ResponseTranslationOption> = {
  response: 'original',
  translatedResponse: 'translated',
};

const getFieldValue = (fieldId: ResponseTextFieldId) => translationOptionMap[fieldId];

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '2px',
    },
  },
});

type ResponseTextFieldId = 'response' | 'translatedResponse';

type ResponseTextFieldProps = {
  fieldId: ResponseTextFieldId;
  defaultLanguage: LanguageSource;
  translationLanguage?: LanguageSource;
  translationTarget?: ResponseTranslationOption;
  selectedResponse: ResponseTranslationOption;
  isGenerating: boolean;
  onChangeSelectedTextbox: (value: ResponseTranslationOption) => void;
  onChangeLanguage: (newLanguage: Option, textFieldId: ResponseTranslationOption) => void;
};

export function ResponseTextField({
  fieldId,
  defaultLanguage,
  translationLanguage,
  translationTarget,
  selectedResponse,
  isGenerating,
  onChangeSelectedTextbox,
  onChangeLanguage,
}: ResponseTextFieldProps) {
  const intl = useIntl();
  const { isMobile, isDesktop } = useResponsive();
  const { setValue, getValues, setFocus, control } = useFormContext<ResponseFormSchema>();
  const { getLanguageByLanguageSource, languages } = useLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const isActive = translationOptionMap[fieldId] === selectedResponse;
  const isRatingDesktopVisible = fieldId === 'response' && hasGeneratedResponse && isDesktop;
  const isRatingMobileVisible = fieldId === 'response' && hasGeneratedResponse && isMobile;

  const handleCopy = (fieldId: ResponseTextFieldId) => {
    navigator.clipboard.writeText(getValues?.(fieldId) as string);
    snackbar.success(
      intl.formatMessage({
        id: 'inbox.response.copy.successful',
        defaultMessage: 'Copied to clipboard',
      })
    );
  };

  const handleEdit = (fieldId: ResponseTextFieldId) => {
    onChangeSelectedTextbox(getFieldValue(fieldId));
    // FIXME: The timeout makes `setFocus` work because the text field needs to change first from disabled to enabled.
    setTimeout(() => setFocus(fieldId), 0);
  };

  return (
    <Stack spacing={0.5}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        {hasGeneratedResponse &&
          (translationLanguage ? (
            <FormControlLabel
              value={getFieldValue(fieldId)}
              control={
                <Radio
                  size="small"
                  disabled={!!translationTarget}
                  checked={isActive}
                  data-testid={`${fieldId}-radio`}
                />
              }
              label={
                <LanguageDropdown
                  fieldId={fieldId}
                  defaultLanguage={defaultLanguage}
                  translationLanguage={translationLanguage}
                  translationTarget={translationTarget}
                  languages={languages}
                  onChangeLanguage={onChangeLanguage}
                  getLanguageByLanguageSource={getLanguageByLanguageSource}
                />
              }
            />
          ) : (
            <LanguageDropdown
              fieldId={fieldId}
              defaultLanguage={defaultLanguage}
              translationLanguage={translationLanguage}
              translationTarget={translationTarget}
              languages={languages}
              onChangeLanguage={onChangeLanguage}
              getLanguageByLanguageSource={getLanguageByLanguageSource}
            />
          ))}
        {isRatingDesktopVisible && <ResponseAIRatingDesktop />}
      </Stack>
      <ResponseFieldActionWrapper
        isDisabled={!!translationTarget}
        isRatingMobileVisible={isRatingMobileVisible}
        onCopy={() => handleCopy(fieldId)}
        onEdit={() => handleEdit(fieldId)}
        onDelete={() => setValue(fieldId, '', { shouldDirty: false })}
      >
        <Box
          style={{ display: 'inline-block', position: 'relative', width: '100%' }}
          onClick={() => {
            !translationTarget && !isActive && handleEdit(fieldId);
          }}
        >
          <Controller
            name={fieldId}
            control={control}
            render={({ field }) => (
              <StyledTextField
                {...field}
                placeholder={intl.formatMessage({
                  id: 'inbox.response.placeholder',
                  defaultMessage: 'Type your response here',
                })}
                inputProps={{ 'data-testid': `${fieldId}-textarea` }}
                disabled={isGenerating || !isActive || !!translationTarget}
                fullWidth
                multiline
                minRows={4}
              />
            )}
          />
          {!isActive && (
            // Disabled text fields block onclick events (in this case also the one of the parent).
            // To solve the problem, this hidden box lays on top of the disabled field.
            <Box style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }} />
          )}
        </Box>
      </ResponseFieldActionWrapper>
    </Stack>
  );
}

function LanguageDropdown({
  fieldId,
  defaultLanguage,
  translationLanguage,
  translationTarget,
  languages,
  onChangeLanguage,
  getLanguageByLanguageSource,
}: {
  fieldId: ResponseTextFieldId;
  defaultLanguage: LanguageSource;
  translationLanguage?: LanguageSource;
  translationTarget?: ResponseTranslationOption;
  languages: Option[];
  onChangeLanguage: (newLanguage: Option, textFieldId: ResponseTranslationOption) => void;
  getLanguageByLanguageSource: (languageSource: LanguageSource) => LanguageItem | undefined;
}) {
  const currentLanguage = fieldId === 'response' ? defaultLanguage : translationLanguage;
  const currentOption: Option = getLanguageByLanguageSource(
    currentLanguage ?? {
      language_source: 'AUTO',
      language_source_name: 'Auto',
    }
  ) ?? {
    label: '',
    value: '',
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <DropDownChip
        title={fieldId}
        isDisabled={!!translationTarget}
        currentValue={currentOption}
        options={languages ?? []}
        onChange={(value) => onChangeLanguage(value, getFieldValue(fieldId))}
      />
    </Stack>
  );
}

import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Button,
  FormControl,
  FormFieldLabel,
  StyledFontAwesomeIcon as Icon,
  TextField,
} from '@trustyou/ui';

import { common, manageDrawer } from '../../constants/messages/customAttributes';
import { styles } from './styles';

const DESCRIPTION_MAX_LENGTH = 100;

export const DescriptionController = () => {
  const { control, getValues } = useFormContext();
  const intl = useIntl();
  const [showDescription, setShowDescription] = useState(!!getValues('description'));

  if (!showDescription) {
    return (
      <Button
        data-testid="show-description-field"
        variant="text"
        sx={{ width: 'fit-content' }}
        onClick={() => setShowDescription(true)}
        startIcon={<Icon icon={faPlus} />}
      >
        {intl.formatMessage(manageDrawer.descriptionAddButton)}
      </Button>
    );
  }

  return (
    <FormControl>
      <FormFieldLabel>{intl.formatMessage(common.description)}</FormFieldLabel>
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            sx={styles.descriptionField}
            size="small"
            placeholder={intl.formatMessage(manageDrawer.descriptionPlaceholder)}
            id="custom-attribute-description"
            type="text"
            variant="outlined"
            multiline
            rows={2}
            inputProps={{
              maxLength: DESCRIPTION_MAX_LENGTH,
            }}
          />
        )}
      />
    </FormControl>
  );
};

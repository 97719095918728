import { useIntl } from 'react-intl';

import type { SxProps, Theme } from '@mui/material';
import type { SelectProps } from '@trustyou/ui';

import { DropdownMultiple } from '../dropdown-multiple';

export type DropdownScoreProps = {
  fieldName: string;
  initialValue: string[];
  size?: SelectProps['size'];
  fullWidth?: SelectProps['fullWidth'];
  sx?: SxProps<Theme>;
};

export const DropdownScore = ({
  fieldName,
  initialValue,
  size = 'medium',
  fullWidth = false,
  sx = {},
}: DropdownScoreProps) => {
  const intl = useIntl();

  const scoreOptions = [
    {
      label: intl.formatMessage({ id: 'inbox.filter.score.noScore', defaultMessage: 'No score' }),
      value: 'no_score',
    },
    {
      label: intl.formatMessage({ id: 'inbox.filter.score.low', defaultMessage: 'Low (0 - 5.9)' }),
      value: 'low',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.score.medium',
        defaultMessage: 'Medium (6 - 7.9)',
      }),
      value: 'medium',
    },
    {
      label: intl.formatMessage({ id: 'inbox.filter.score.high', defaultMessage: 'High (8 - 10)' }),
      value: 'high',
    },
  ];

  return (
    <DropdownMultiple
      fieldName={fieldName}
      options={scoreOptions}
      allValuesLabel={intl.formatMessage({
        id: 'inbox.filter.score.all',
        defaultMessage: 'All Scores',
      })}
      dataTestId="filterFieldScore"
      label={intl.formatMessage({
        id: 'inbox.filter.rating.label',
        defaultMessage: 'Rating',
      })}
      initialSelectedValues={initialValue}
      showLabelAsResult
      size={size}
      fullWidth={fullWidth}
      sx={sx}
    />
  );
};

/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faCheck, faGripDotsVertical, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  FormControl,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

import { useUnregisterUnmountedQuestion } from '../../../../../service/hooks/use-unregister-unmounted-question';
import { QUESTION_TYPES_BY_CATEGORY } from '../../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../../shared';
import { QUESTION_LIBRARY_EDITOR_CHECKBOX_INSTRUCTIONS } from '../../../constants/questions';

export function QuestionEditorBinaryType() {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const isQuestionTypeVariationButtonGroup = watch('questionTypeVariation') === 'button_group';
  const { fields, replace, move, update } = useFieldArray({
    control,
    name: 'binaryOptions',
  });
  useUnregisterUnmountedQuestion({
    questionTypes: QUESTION_TYPES_BY_CATEGORY.binary,
    fieldsToUnregister: ['binaryOptions', 'binary'],
  });

  useEffect(() => {
    if (isQuestionTypeVariationButtonGroup) {
      replace([
        {
          value: 0,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          label: fields[0]?.label || '',
          icon: faCheck,
          color: 'success',
          helperText: 'Positive response',
        },
        {
          value: 1,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          label: fields[1]?.label || '',
          icon: faXmark,
          color: 'error',
          helperText: 'Negative response',
        },
      ]);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      replace([{ value: 0, label: fields[0]?.label || '' }]);
      setValue('questionText', '');
      setValue('additionalText', '');
      clearErrors('questionText');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuestionTypeVariationButtonGroup, replace]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    move(source.index, destination.index);
  };

  return (
    <>
      {isQuestionTypeVariationButtonGroup ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
            <FormattedMessage id="survey.common.answer-options" defaultMessage="Answer options" />
          </Typography>
          <Droppable droppableId="droppable-options">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={3}
                sx={{ alignItems: 'flex-start' }}
              >
                {fields.map((field, index) => (
                  <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                    {(provided) => (
                      <Stack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={field.id}
                        sx={{ flexDirection: 'row', width: '100%' }}
                      >
                        <IconButton size="medium">
                          <Icon icon={faGripDotsVertical} />
                        </IconButton>
                        <Controller
                          name={`binaryOptions[${index}].label`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              variant="filled"
                              fullWidth
                              size="small"
                              hiddenLabel
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              helperText={fields[index]?.helperText}
                              error={
                                errors?.binaryOptions &&
                                Array.isArray(errors.binaryOptions) &&
                                !!errors.binaryOptions[index]?.label
                              }
                              value={field.value}
                              onChange={field.onChange}
                              onBlur={(e) => {
                                update(index, {
                                  ...fields[index],
                                  label: e.target.value,
                                });
                              }}
                            />
                          )}
                        />
                      </Stack>
                    )}
                  </Draggable>
                ))}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Stack spacing={2}>
          {fields?.map((field, index) => (
            <FormControl key={field.id} variant="standard" fullWidth>
              <InputLabel shrink>
                <FormattedMessage id="survey.common.text" defaultMessage="Text" />
              </InputLabel>
              <Controller
                name={`binaryOptions[${index}].label`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomTextFieldWithSeparatedLabel
                    variant="outlined"
                    fullWidth
                    size="small"
                    hiddenLabel
                    multiline
                    minRows={5}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      update(index, {
                        ...fields[index],
                        label: e.target.value,
                      });
                    }}
                    error={
                      errors?.binaryOptions &&
                      Array.isArray(errors.binaryOptions) &&
                      !!errors.binaryOptions[index]?.label
                    }
                  />
                )}
              />
            </FormControl>
          ))}
          <Stack spacing={2}>
            {QUESTION_LIBRARY_EDITOR_CHECKBOX_INSTRUCTIONS.map((instruction, index) => (
              <Stack spacing={1} key={index}>
                <Typography variant="subtitle2">{instruction.title}</Typography>
                <Typography variant="body2">{instruction.description}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
}

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  type BasicEntity,
  type Benchmark,
  commonFields,
  getParsedBenchmarkRules,
  useBenchmarkAdditionalEntitiesByIds,
} from '@trustyou/shared';
import {
  Alert,
  Button,
  ComposableDrawerWithStickyFooter,
  DiscardChangesModal,
  Stack,
  Tab,
  Tabs,
} from '@trustyou/ui';

import { CompetitorsTab } from './CompetitorsTab';
import { DEFAULT_DEFINITION_RULE } from './DefinitionRuleSelector/DefinitionRuleSelector';
import { EntitiesTab } from './EntitiesTab';
import { NameController } from './NameController';
import { SaveAction } from './SaveAction';
import { SetController } from './SetController';

import { useBenchmarkStore } from '../../..//store';
import { DEFAULT_SET_NAME } from '../../../constants/benchmarks';
import { manageDrawer } from '../../../constants/messages/benchmarks';
import { styles } from './styles';

export interface FormRef {
  onCancel: () => void;
}

type Props = {
  data?: Benchmark;
  onClose: () => void;
};

export interface FormData {
  id?: string;
  name: string;
  set_name?: string;
  selectedDefinitionTab: string;
  additional_entities: BasicEntity[];
  additional_competitors: BasicEntity[];
}

export const Form = forwardRef<FormRef, Props>(({ data, onClose }, ref) => {
  const intl = useIntl();
  const { setDeletableBenchmark } = useBenchmarkStore();
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const entitiesRules = data
    ? getParsedBenchmarkRules(data?.definition?.owned.rule ?? '')
    : [DEFAULT_DEFINITION_RULE];

  const competitorsRules = data
    ? getParsedBenchmarkRules(data?.definition?.competitors.rule ?? '')
    : [DEFAULT_DEFINITION_RULE];

  const additionalEntitiesIds = data?.definition?.owned?.additional_entities || [];
  const additionalCompetitorsIds = data?.definition?.competitors?.additional_entities || [];

  const { data: additionalEntities = [] } = useBenchmarkAdditionalEntitiesByIds([
    ...new Set([...additionalEntitiesIds, ...additionalCompetitorsIds]),
  ]);

  const defaultValues = {
    id: data?.id,
    name: data?.name || '',
    set_name: data?.set_name || DEFAULT_SET_NAME,
    entities_rules: entitiesRules,
    competitors_rules: competitorsRules,
    additional_entities: additionalEntitiesIds.map((id) => ({
      id,
    })),
    additional_competitors: additionalCompetitorsIds.map((id) => ({
      id,
    })),
    isUnmanagedBenchmark: data && !data.definition,
    selectedDefinitionTab: 'entities',
  };

  const isUnmanagedBenchmark = data && !data.definition;

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues,
  });

  const { formState, trigger, setValue, watch } = methods;

  const selectedTab = watch('selectedDefinitionTab');

  useEffect(() => {
    // Currently in edit mode, it takes at lease two changes to trigger validity of the form.
    // This trick will fix it. But there could be a better solution
    if (data) trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (additionalEntities.length) {
      setValue(
        'additional_entities',
        additionalEntities.filter((row) => additionalEntitiesIds.includes(row.id))
      );
      setValue(
        'additional_competitors',
        additionalEntities.filter((row) => additionalCompetitorsIds.includes(row.id))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalEntities]);

  const onCancel = () => {
    if (Object.keys(formState.dirtyFields).length) {
      setShowCancelConfirmation(true);
    } else {
      onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    onCancel,
  }));

  return (
    <FormProvider {...methods}>
      <ComposableDrawerWithStickyFooter.Header
        title={intl.formatMessage(data ? manageDrawer.editHeader : manageDrawer.createHeader)}
        onClose={onCancel}
        sx={styles.formHeader}
      />
      <ComposableDrawerWithStickyFooter.Content sx={styles.formContent}>
        <Stack gap={3}>
          {isUnmanagedBenchmark && (
            <Alert severity="info" sx={{ marginBottom: 2 }}>
              {intl.formatMessage(manageDrawer.unmanagedBenchmarkInfo)}
            </Alert>
          )}
          <NameController />
          <SetController />
          {!isUnmanagedBenchmark && (
            <Stack gap={2}>
              <Tabs
                sx={styles.tabsBar}
                value={selectedTab}
                onChange={(_, newValue) => {
                  setValue('selectedDefinitionTab', newValue);
                }}
              >
                <Tab data-testid="benchmark-edit-entities" value="entities" label="Own entities" />
                <Tab
                  data-testid="benchmark-edit-competitors"
                  value="competitors"
                  label="Competitor entities"
                />
              </Tabs>
              {selectedTab === 'entities' && <EntitiesTab />}
              {selectedTab === 'competitors' && <CompetitorsTab />}
            </Stack>
          )}
        </Stack>
      </ComposableDrawerWithStickyFooter.Content>
      <ComposableDrawerWithStickyFooter.Footer sx={styles.formFooter}>
        {data && (
          <Button
            onClick={() => setDeletableBenchmark(data)}
            color="error"
            sx={styles.deleteButton}
          >
            {intl.formatMessage(manageDrawer.deleteButton)}
          </Button>
        )}
        <Button onClick={onCancel} type="button">
          {intl.formatMessage(commonFields.cancel)}
        </Button>
        <SaveAction data={data} onSave={onClose} />
      </ComposableDrawerWithStickyFooter.Footer>
      <DiscardChangesModal
        open={showCancelConfirmation}
        onClose={() => setShowCancelConfirmation(false)}
        onDiscard={onClose}
      />
    </FormProvider>
  );
});

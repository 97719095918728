import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { CustomAttributeType } from '@trustyou/shared';
import { FormControl, FormFieldLabel, MenuItem, Select, Typography } from '@trustyou/ui';

import { common, manageDrawer } from '../../constants/messages/customAttributes';
import { CustomAttributeTypeLabel } from '../CustomAttributeTypeLabel/CustomAttributeTypeLabel';

export const TypeController = () => {
  const { control, setValue, getValues } = useFormContext();
  return (
    <FormControl>
      <FormFieldLabel required>
        <FormattedMessage {...common.type} />
      </FormFieldLabel>
      <Controller
        control={control}
        name="type"
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange } }) => (
          <>
            <Select
              sx={{ maxWidth: '300px' }}
              data-testid="custom-attribute-type"
              value={value}
              onChange={(event) => {
                onChange(event.target.value);
                if (getValues('type') === CustomAttributeType.TEXT) {
                  setValue('options', []);
                } else {
                  setValue('options', [{ name: '', id: `NEW_${Date.now()}` }]);
                }
              }}
              size="small"
              displayEmpty
              disabled={!!control._defaultValues?.type}
              renderValue={(value) =>
                value ? (
                  <CustomAttributeTypeLabel type={value} />
                ) : (
                  <Typography color="text.secondary">
                    <FormattedMessage {...manageDrawer.typePlaceholder} />
                  </Typography>
                )
              }
            >
              {Object.values(CustomAttributeType).map((option) => (
                <MenuItem key={option} value={option}>
                  <CustomAttributeTypeLabel type={option} />
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" marginTop={1}>
              <FormattedMessage
                {...(control._defaultValues?.type
                  ? manageDrawer.typeUnchangeableEditMode
                  : manageDrawer.typeUnchangeableCreateMode)}
              />
            </Typography>
          </>
        )}
      />
    </FormControl>
  );
};

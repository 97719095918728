import {
  type AccessGroup,
  type AdvancedSelectionScope,
  type BasicEntity,
  type Entity,
  type ManagedEntityListScope,
  type SegmentGroup,
  USER_SCOPES,
  getStringifiedScopeRules,
} from '@trustyou/shared';
import { create } from 'zustand';

type ScopeState = {
  selectedScope: USER_SCOPES;
  setSelectedScope: (scope: USER_SCOPES) => void;

  selectedEntities: Entity[] | BasicEntity[];
  setSelectedEntities: (entities: Entity[] | BasicEntity[]) => void;

  selectedGroups: SegmentGroup[];
  setSelectedGroups: (groups: SegmentGroup[]) => void;

  selectedAccessGroup?: AccessGroup;
  setSelectedAccessGroup: (accessGroup?: AccessGroup) => void;

  advancedSelectionScope?: AdvancedSelectionScope;
  setAdvancedSelectionScope: (advancedSelectionScope?: AdvancedSelectionScope) => void;

  getSavingAdvancedSelectionScope: () => null | ManagedEntityListScope;
  isValidAdvancedSelectionScope: () => boolean;

  isValid: () => boolean;
};

export const useScopeStore = create<ScopeState>((set, get) => ({
  selectedScope: 'none' as USER_SCOPES.NONE,
  setSelectedScope: (selectedScope) => set(() => ({ selectedScope })),

  selectedEntities: [],
  setSelectedEntities: (selectedEntities) => set(() => ({ selectedEntities })),

  selectedGroups: [],
  setSelectedGroups: (selectedGroups) => set(() => ({ selectedGroups })),

  setSelectedAccessGroup: (selectedAccessGroup) => set(() => ({ selectedAccessGroup })),

  setAdvancedSelectionScope: (advancedSelectionScope) => set(() => ({ advancedSelectionScope })),

  getSavingAdvancedSelectionScope: () => {
    const advancedSelectionScope = get().advancedSelectionScope;
    if (!advancedSelectionScope) return null;
    return {
      rule: getStringifiedScopeRules(advancedSelectionScope.rules),
      additional_entities: advancedSelectionScope.additional_entities.map((row) => row.id),
    };
  },

  isValidAdvancedSelectionScope: () => {
    const advancedSelectionScope = get().advancedSelectionScope;
    if (!advancedSelectionScope) return false;
    const validRules = advancedSelectionScope.rules.filter((rule) => rule.values.length > 0);
    if (!validRules.length) return false;
    return true;
  },

  isValid: () =>
    get().selectedScope === USER_SCOPES.ALL_ENTITIES ||
    (get().selectedScope === USER_SCOPES.BY_ENTITIES && get().selectedEntities.length > 0) ||
    (get().selectedScope === USER_SCOPES.ACCESS_GROUP && !!get().selectedAccessGroup) ||
    (get().selectedScope === USER_SCOPES.BY_GROUPS && get().selectedGroups.length > 0) ||
    (get().selectedScope === USER_SCOPES.ADVANCED_SELECTION &&
      get().isValidAdvancedSelectionScope()) ||
    get().selectedScope === USER_SCOPES.NONE,
}));

import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { yupResolver } from '@hookform/resolvers/yup';
import { type MutateOptions, useQueryClient } from '@tanstack/react-query';
import { useEntity, useLanguageStore, useResponsive, validationErrors } from '@trustyou/shared';
import { FormControl, RadioGroup, Stack, snackbar } from '@trustyou/ui';
import * as yup from 'yup';

import { GDPRAlert } from './gdpr-alert';
import { PrivateResponseSection } from './private-response/private-response-section';
import { ResponseTemplate } from './response-template';
import { ResponseTextField } from './response-text-field';
import { TranslateButton } from './translate-button';
import { UpdateTranslationButton } from './update-translation-button';

import type { LanguageEnum, TranslateIn } from '../../../../client';
import {
  MARKED_AS_RESPONDED_WITH_AN_EMPTY_TEXT,
  TRUSTYOU_SURVEY_DOMAIN_NAME,
} from '../../../../constants';
import {
  type LanguageSource,
  newAbortController,
  resetAbortController,
  updateReviewStatusById,
  useGenerateResponseAI,
  useLanguage,
  useResponseAIProfile,
  useReview,
  useSaveResponse,
  useTranslateViaResponseAI,
} from '../../../../hooks';
import useSaveResponsePermission from '../../../../hooks/permissions/use-save-response-permission';
import { useStore } from '../../../../store/store';
import {
  type ErrorWithResponse,
  type Language,
  type LanguageItem,
  type ResponseAITranslateData,
  type ResponseFormSchema,
  type ResponseResult,
  ResponseTabsEnum,
  type ResponseTranslationOption,
} from '../../../../types';
import type { Option } from '../../../dropdowns/dropdown-chip';
import { ReplaceDialog } from '../../../response-ai/replace-dialog';
import { ResponseAIActions } from '../../../response-ai/response-ai-actions/response-ai-actions';
import { SubmitRegion } from './submit-region';

export type ResponseFormProps = {
  formType: ResponseTabsEnum;
  onGenerateResponseAI: (templatesUsed: ResponseResult['templates_used']) => void;
};

// TODO: Refactor this component to encapsulate different logic into custom hooks, reducing the number of states and `useEffect`
export function ResponseForm({ formType, onGenerateResponseAI }: ResponseFormProps) {
  const { isMobile } = useResponsive();
  const { accessTokenPayload } = useOidcAccessToken();
  const isSaveResponseAllowed = useSaveResponsePermission();
  const { locale } = useLanguageStore();
  const {
    getLanguageSourceByCode,
    getLanguageSourceByLanguage,
    getFormattedLanguageCode,
    fallBackLanguage,
    fallBackLanguageSource,
  } = useLanguage();
  const { reviewId = '' } = useParams();
  const queryClient = useQueryClient();
  const { data: aiSettings } = useResponseAIProfile();
  const saveResponse = useSaveResponse();

  const { data: userProfile } = useResponseAIProfile();

  const { data: reviewRoot } = useReview({ reviewId });
  const isSurvey = reviewRoot?.review.domain_name === TRUSTYOU_SURVEY_DOMAIN_NAME;
  const isPublicSurvey = reviewRoot?.survey?.privacy_level === 'public';
  const isPrivateSurvey = reviewRoot?.survey?.privacy_level === 'private';

  const { data: entity } = useEntity(reviewRoot?.meta?.entity_id ?? '');

  const generateResponseAI = useGenerateResponseAI();

  const intl = useIntl();
  const invalidError = intl.formatMessage(validationErrors.invalidEmail);
  const requiredError = intl.formatMessage(validationErrors.required);
  const subjectText = intl.formatMessage(
    {
      id: 'inbox.response.email-details.default-subject',
      defaultMessage: 'Reply from {entityName}',
    },
    { entityName: entity?.name }
  );

  const generationLanguage = useStore.use.generationLanguage();
  const updateIsSubmitDisabled = useStore.use.updateIsSubmitDisabled();
  const updateIsResponseFormDirty = useStore.use.updateIsResponseFormDirty();

  const hasExistingResponse = useStore.use.hasExistingResponse();
  const updateHasExistingResponse = useStore.use.updateHasExistingResponse();

  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const updateHasGeneratedResponse = useStore.use.updateHasGeneratedResponse();
  const updateAcceptsDirectResponse = useStore.use.updateAcceptsDirectResponse();

  const schema = yup.object().shape({
    response: hasExistingResponse ? yup.string() : yup.string().required(),
    alsoSendViaEmail: yup.boolean().default(false),
    emailDetails: yup.object().shape({
      subject: yup.string().required(requiredError),
      from: yup.string().email(invalidError).required(requiredError),
      cc: yup.array().of(yup.string().email(invalidError)),
      bcc: yup.array().of(yup.string().email(invalidError)),
    }),
  });

  const methods = useForm<ResponseFormSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      response: reviewRoot?.response?.text ?? '',
      translatedResponse: '',
      alsoSendViaEmail: isPrivateSurvey,
      emailDetails: {
        subject: subjectText,
        body: '',
        from: accessTokenPayload.email,
        attachReview: true,
      },
    },
  });
  const {
    setValue,
    reset,
    getValues,
    formState: { isDirty },
  } = methods;

  const [defaultLanguage, setDefaultLanguage] = useState<LanguageSource>(fallBackLanguageSource);
  const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [translationTarget, setTranslationTarget] = useState<
    ResponseTranslationOption | undefined
  >();
  const [translationLanguage, setTranslationLanguage] = useState<LanguageSource>();
  const [isTranslating, setIsTranslating] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState<ResponseTranslationOption>('original');

  const resetResponseValues = useCallback(() => {
    resetAbortController();

    // Update review booleans when review changes
    updateHasExistingResponse(!!reviewRoot?.response);
    updateAcceptsDirectResponse(reviewRoot?.meta?.directly_respondable ?? false);

    // Reset response ai
    setIsGenerating(false);
    updateHasGeneratedResponse(false);
    // setGuidesUsed(undefined);
    setTranslationLanguage(undefined);
    setTranslationTarget(undefined);

    // Clean the inputs
    setSelectedResponse('original');
    reset({
      response: '',
      translatedResponse: '',
      alsoSendViaEmail: isPrivateSurvey,
      // emailDetails: initialEmailDetails,
    });
  }, [
    isPrivateSurvey,
    reset,
    reviewRoot?.meta?.directly_respondable,
    reviewRoot?.response,
    updateAcceptsDirectResponse,
    updateHasExistingResponse,
    updateHasGeneratedResponse,
  ]);

  useEffect(() => {
    // Reset everything when user navigates away (i.e. by refreshing/browser back)
    return () => {
      resetResponseValues();
    };
  }, [resetResponseValues]);

  useEffect(() => {
    // Reset everything when review changes
    resetResponseValues();
  }, [resetResponseValues, reviewRoot?.id]);

  useEffect(() => {
    // Enable leave dialog when i.e. back to inbox icon is clicked
    updateIsResponseFormDirty?.(isDirty || isGenerating);
  }, [isDirty, isGenerating, updateIsResponseFormDirty]);

  useEffect(() => {
    /** Disables submit button when
     * - is read only role
     * - selected (generated) text field is empty
     * - or the general form is not dirty or valid
     */
    if (!isSaveResponseAllowed) {
      updateIsSubmitDisabled(true);
      return;
    }

    if (hasGeneratedResponse) {
      if (selectedResponse === 'original') {
        updateIsSubmitDisabled(!getValues('response'));
      } else {
        updateIsSubmitDisabled(!getValues('translatedResponse'));
      }
      return;
    }
    updateIsSubmitDisabled(!isDirty || !getValues('response'));
  }, [
    isDirty,
    hasGeneratedResponse,
    selectedResponse,
    isSaveResponseAllowed,
    updateIsSubmitDisabled,
    getValues,
  ]);

  // --- End of hooks and state management ---

  const isTranslatedTextSelected = selectedResponse === 'translated';

  const handleGenerateResponseAI = ({
    replace,
    skipDialogNextTime,
  }: { replace?: boolean; skipDialogNextTime?: boolean } = {}) => {
    if (replace) {
      if (isReplaceDialogOpen) {
        setIsReplaceDialogOpen(!isReplaceDialogOpen);
      }
      if (skipDialogNextTime) {
        localStorage.setItem('skipDialogBeforeGenerateAnother', 'true');
      }
    } else {
      const skipDialog = Boolean(localStorage.getItem('skipDialogBeforeGenerateAnother'));
      if (hasGeneratedResponse && !skipDialog) {
        setIsReplaceDialogOpen(!isReplaceDialogOpen);
        return;
      }
    }

    // Reset fields. Only show one textbox when generating a response.
    // setGuidesUsed(undefined);
    setValue('translatedResponse', undefined);
    setTranslationLanguage(undefined);
    setTranslationTarget(undefined);
    setSelectedResponse('original');

    newAbortController();
    setIsGenerating(true);
    generateResponseAI.mutate(
      {
        review_id: reviewRoot?.id ?? '',
        language: generationLanguage.value,
      },
      {
        onSuccess: async (data: ResponseResult) => {
          setValue('response', data.response_text, {
            shouldDirty: !!data.response_text,
          });
          onGenerateResponseAI(data.templates_used);

          const responseLang = getLanguageSourceByCode(data.response_language);
          responseLang && setDefaultLanguage(responseLang);

          resetAbortController();
          setIsGenerating(false);
          updateHasGeneratedResponse(true);
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.response.generate.success',
              defaultMessage: 'Response generated',
            })
          );
        },
        onError: () => {
          setIsGenerating(false);
          snackbar.error(
            intl.formatMessage({
              id: 'inbox.response.generate.feedbackTitleErrorGeneral',
              defaultMessage: 'Couldn’t generate a response, please try again',
            })
          );
          resetAbortController();
        },
      }
    );
  };

  const translateViaResponseAI = useTranslateViaResponseAI();
  const translationMutateOptions: MutateOptions<
    ResponseAITranslateData,
    Error,
    TranslateIn,
    unknown
  > = {
    onSuccess: (data) => {
      const fieldId = translationTarget === 'original' ? 'response' : 'translatedResponse';
      setValue(fieldId, data?.text, { shouldDirty: !!data?.text });
      setIsTranslating(false);
      setTranslationTarget(undefined);
    },
    onError: () => {
      setIsTranslating(false);
      setIsGenerating(false);
      setTranslationTarget(undefined);
    },
  };

  const handleTranslationOptionSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedResponse(event.target.value as ResponseTranslationOption);
  };

  const handleChangeLanguage = (newLanguage: Option, textField: ResponseTranslationOption) => {
    setTranslationTarget(textField);

    const newLanguageSource = getLanguageSourceByLanguage(newLanguage as LanguageItem);
    if (textField === 'original') setDefaultLanguage(newLanguageSource);
    else setTranslationLanguage(newLanguageSource);

    const textToTranslate =
      textField === 'original' ? getValues('response') : getValues('translatedResponse');
    if (!textToTranslate) return;

    translateViaResponseAI.mutate(
      {
        text: textToTranslate,
        target_language: newLanguage.value as Language,
        tone_of_voice: aiSettings?.tone_of_voice ?? 'formal',
      },
      translationMutateOptions
    );
  };

  /**
   * Updates the text of the unselected field based
   * on the text of the selected field
   * @param updateDefault - if true, updates the text of the default text field
   * @param language - if provided, translates the given text into this language
   */
  const handleUpdateTranslation = (
    updateDefault = isTranslatedTextSelected,
    language?: LanguageEnum
  ) => {
    setIsTranslating(true);
    // keep the language
    const targetLanguage = updateDefault
      ? defaultLanguage?.language_source
      : (language ?? translationLanguage?.language_source);
    let translateLang = getFormattedLanguageCode(targetLanguage?.toLowerCase() ?? '');

    if (!translateLang || targetLanguage === 'AUTO' || targetLanguage === 'UND') {
      // fallback to english
      translateLang = fallBackLanguage;
    }

    // update the text based on the opposite (selected) field
    const text = updateDefault ? getValues('translatedResponse') : getValues('response');
    if (!text) return;

    setTranslationTarget(updateDefault ? 'original' : 'translated');
    translateViaResponseAI.mutate(
      {
        text: text ?? '',
        target_language: translateLang ?? fallBackLanguage,
        tone_of_voice: aiSettings?.tone_of_voice ?? 'formal',
      },
      translationMutateOptions
    );
  };

  const onSubmit = () => {
    let publicResponseText = isTranslatedTextSelected
      ? getValues('translatedResponse')
      : getValues('response');
    const privateResponseText = getValues('emailDetails.sendDifferentResponse')
      ? getValues('emailDetails.differentResponse')
      : isTranslatedTextSelected
        ? getValues('translatedResponse')
        : getValues('response');
    const shouldIncludePrivateResponse = isPrivateSurvey || getValues('alsoSendViaEmail');
    const surveyResponseText = isPrivateSurvey ? undefined : publicResponseText;

    if (!isSurvey && !publicResponseText) {
      publicResponseText = MARKED_AS_RESPONDED_WITH_AN_EMPTY_TEXT;
    }

    saveResponse.mutate(
      {
        reviewId: reviewRoot?.id ?? '',
        payload: {
          // TODO: SIN-390 remove this line to stop sending the author to the backend
          author: accessTokenPayload.name,
          text: isSurvey ? surveyResponseText : publicResponseText,
          private_response: shouldIncludePrivateResponse
            ? {
                text: privateResponseText ?? '',
                subject: getValues('emailDetails.subject') ?? '',
                sender: getValues('emailDetails.from'),
                cc: getValues('emailDetails.cc'),
                bcc: getValues('emailDetails.bcc'),
                attach_feedback: getValues('emailDetails.attachReview') ?? false,
              }
            : null,
        },
      },
      {
        onSuccess: () => {
          updateReviewStatusById(queryClient, reviewRoot?.id ?? '', 'responded');
          resetResponseValues();
          updateHasExistingResponse(true);
        },
        onError: (err) => {
          const { response } = err as unknown as ErrorWithResponse;
          if (response.data.detail) {
            snackbar.error(response.data.detail);
          } else {
            snackbar.genericError();
          }
        },
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        spacing={4}
        sx={{
          maxWidth: '80ch',
          padding: 2,
          ...(isMobile && {
            paddingBlockEnd: 10,
          }),
        }}
      >
        <Stack spacing={2}>
          {isPublicSurvey && <GDPRAlert />}
          {formType === ResponseTabsEnum.RESPONSE_AI && (
            <ResponseAIActions
              isGenerating={isGenerating}
              onGenerateResponseAI={handleGenerateResponseAI}
            />
          )}
          {formType === ResponseTabsEnum.TEMPLATE && (
            <ResponseTemplate
              onSelect={(value: string) => {
                setValue('response', value, { shouldDirty: true });
              }}
            />
          )}
          <FormControl fullWidth>
            <RadioGroup defaultValue="original" onChange={handleTranslationOptionSelection}>
              <ResponseTextField
                fieldId="response"
                defaultLanguage={defaultLanguage}
                translationLanguage={translationLanguage}
                translationTarget={translationTarget}
                selectedResponse={selectedResponse}
                isGenerating={isGenerating}
                onChangeSelectedTextbox={setSelectedResponse}
                onChangeLanguage={handleChangeLanguage}
              />
              {/* Conditionally show the 'Translate' button OR the 'Update translation' button plus the second textbox for the translated text. */}
              {hasGeneratedResponse &&
                (translationLanguage ? (
                  <>
                    <UpdateTranslationButton
                      disabled={!!translationTarget}
                      isLoading={isTranslating}
                      onClick={() => handleUpdateTranslation()}
                    />
                    <ResponseTextField
                      fieldId="translatedResponse"
                      defaultLanguage={defaultLanguage}
                      translationLanguage={translationLanguage}
                      translationTarget={translationTarget}
                      selectedResponse={selectedResponse}
                      isGenerating={isGenerating}
                      onChangeSelectedTextbox={setSelectedResponse}
                      onChangeLanguage={handleChangeLanguage}
                    />
                  </>
                ) : (
                  <TranslateButton
                    disabled={!!translationTarget}
                    onClick={() => {
                      const isAutoOrUndefined = userProfile
                        ? ['AUTO', 'UND'].includes(userProfile?.translation_language ?? '')
                        : true;
                      const lang = isAutoOrUndefined ? locale : userProfile?.translation_language;
                      // @ts-expect-error Type 'undefined' is not assignable to type 'LanguageSource'.
                      setTranslationLanguage(getLanguageSourceByCode(lang));
                      handleUpdateTranslation(false, lang as LanguageEnum);
                    }}
                  />
                ))}
            </RadioGroup>
          </FormControl>
          {isSurvey && <PrivateResponseSection selectedResponse={selectedResponse} />}
        </Stack>
        <SubmitRegion onSubmit={onSubmit} />
      </Stack>
      <ReplaceDialog
        isOpen={isReplaceDialogOpen}
        onClose={() => setIsReplaceDialogOpen(!isReplaceDialogOpen)}
        onSubmit={(isChecked) =>
          handleGenerateResponseAI({ replace: true, skipDialogNextTime: isChecked })
        }
      />
    </FormProvider>
  );
}

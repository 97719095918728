import { type Theme, useMediaQuery, useTheme } from '@mui/material';

const getCurrentBreakpoint = (theme: Theme) => {
  const viewportWidth = window.innerWidth;
  let currentBreakpoint = '';
  theme.breakpoints.keys.forEach((key, index) => {
    const nextKey = theme.breakpoints.keys[index + 1];
    if (
      !currentBreakpoint &&
      theme.breakpoints.values[nextKey] &&
      viewportWidth < theme.breakpoints.values[nextKey]
    ) {
      currentBreakpoint = key;
    }
  });
  return currentBreakpoint || 'xl';
};

export const useResponsive = () => {
  const theme = useTheme();

  const currentBreakpoint = getCurrentBreakpoint(theme);

  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = isPhone || isTablet;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return {
    currentBreakpoint,
    isPhone,
    isTablet,
    isMobile,
    isDesktop,
  };
};

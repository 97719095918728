import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { faUserRobot } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useRouteUtils } from '@trustyou/shared';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@trustyou/ui';

import { useLanguage, useLeaveDialog } from '../../../hooks';
import { useStore } from '../../../store/store';
import type { Language } from '../../../types';

export type ResponseAIActionsProps = {
  isGenerating: boolean;
  onGenerateResponseAI: () => void;
};

export const ResponseAIActions = ({
  isGenerating,
  onGenerateResponseAI,
}: ResponseAIActionsProps) => {
  const intl = useIntl();
  const { navigateWithBase } = useRouteUtils();
  const updateGenerationLanguage = useStore.use.updateGenerationLanguage();
  const generationLanguage = useStore.use.generationLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const isResponseFormDirty = useStore.use.isResponseFormDirty();
  const { languagesWithAuto, getLanguageByCode, isLoading: isLanguagesLoading } = useLanguage();
  const { renderLeaveDialog, canContinue } = useLeaveDialog({
    showDialog: isResponseFormDirty,
  });

  useEffect(() => {
    if (isLanguagesLoading) return;
    const storedLanguage = localStorage.getItem('generateAIResponseLanguage') as Language;
    const initialLanguage = getLanguageByCode(storedLanguage);
    initialLanguage && updateGenerationLanguage(initialLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLanguagesLoading]);

  const languageFieldLabel = intl.formatMessage({
    id: 'inbox.response.ai.language.label',
    defaultMessage: 'Language',
  });

  const responseAIButtonInitialText = hasGeneratedResponse
    ? intl.formatMessage({
        id: 'inbox.response.ai.generateAnother',
        defaultMessage: 'Generate another',
      })
    : intl.formatMessage({
        id: 'inbox.response.ai.generateResponse',
        defaultMessage: 'Generate response',
      });
  const responseAIGeneratingText = intl.formatMessage({
    id: 'inbox.response.ai.generating',
    defaultMessage: 'Generating...',
  });

  const goToSettings = async () => {
    if (await canContinue()) {
      navigateWithBase('settings/user/inbox');
    }
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" sx={{ gap: 2, flexWrap: 'wrap', alignItems: 'center' }}>
        <FormControl size="small" sx={{ width: 220 }}>
          <InputLabel shrink id="response-ai-language-label">
            {languageFieldLabel}
          </InputLabel>
          <Select
            aria-labelledby="response-ai-language-label"
            label={languageFieldLabel}
            value={generationLanguage.value}
            onChange={(event) => {
              const newLanguage = getLanguageByCode(event.target.value as Language | 'AUTO');
              localStorage.setItem('generateAIResponseLanguage', newLanguage?.value || 'AUTO');
              newLanguage && updateGenerationLanguage(newLanguage);
            }}
            MenuProps={{
              sx: {
                maxHeight: 460,
              },
            }}
          >
            {languagesWithAuto.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LoadingButton
          loading={isGenerating}
          loadingPosition="start"
          startIcon={<FontAwesomeIcon icon={faUserRobot} />}
          color="primary"
          variant="contained"
          onClick={onGenerateResponseAI}
          data-gtm-id={`inbox_response_ai_generate_${
            hasGeneratedResponse ? 'another' : 'response'
          }`}
        >
          {isGenerating ? responseAIGeneratingText : responseAIButtonInitialText}
        </LoadingButton>
        <Button
          onClick={goToSettings}
          variant="text"
          size="small"
          color="primary"
          data-gtm-id="inbox_settings_button"
        >
          <FormattedMessage id="inbox.settings" defaultMessage="Settings" />
        </Button>
      </Stack>
      {renderLeaveDialog()}
    </Stack>
  );
};

import type { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { type NodeType, useSidebarStore } from '@trustyou/shared';
import {
  Divider,
  ICON_BUTTON_WIDTH,
  ListItem,
  NavIcon,
  NavListItem,
  Typography,
} from '@trustyou/ui';

export type DrawerNodeProps = {
  node: NodeType;
  isPermanentlyExpanded?: boolean;
};

export function DrawerNode({ node, isPermanentlyExpanded }: DrawerNodeProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isCollapsed } = useSidebarStore();

  const translatedNodeName = intl.formatMessage({ id: node.id, defaultMessage: node.name });

  const navProps = {
    id: node.id,
    icon: node.icon,
    selected: !!(node.path && pathname.includes(node.path)),
    onClick: (event: MouseEvent) => {
      if (node.path) {
        if (event.metaKey || event.ctrlKey) {
          window.open(`${process.env.PUBLIC_URL}${node.path}`, '_blank');
        } else {
          navigate(node.path, { state: { path: pathname } });
        }
      } else {
        node.onClick?.();
      }
    },
  };

  if (node.childNodes && node.childNodes.length > 0) {
    return (
      <>
        {isPermanentlyExpanded || !isCollapsed ? (
          <Typography
            variant="overline"
            sx={{
              marginTop: 3,
              paddingInline: 2,
              color: 'text.secondary',
            }}
          >
            {translatedNodeName}
          </Typography>
        ) : (
          <Divider
            flexItem
            sx={{
              width: ICON_BUTTON_WIDTH,
              marginBlockStart: 5,
              marginBlockEnd: 1.85,
            }}
          />
        )}
        {node.childNodes
          .filter((node) => !node.isHidden)
          .map((childNode) => (
            <DrawerNode
              key={childNode.id}
              node={childNode}
              isPermanentlyExpanded={isPermanentlyExpanded}
            />
          ))}
      </>
    );
  }

  return (
    <ListItem disablePadding disableGutters>
      {isPermanentlyExpanded || !isCollapsed ? (
        <NavListItem {...navProps} name={translatedNodeName} />
      ) : (
        <NavIcon {...navProps} altText={node.name} tooltip={translatedNodeName} />
      )}
    </ListItem>
  );
}

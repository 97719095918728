import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_KEY,
  type Entity,
  FETCH_INFINITE_COMPETITORS,
  useInfiniteCompetitors,
} from '@trustyou/shared';
import { ListItemText } from '@trustyou/ui';

import { MAX_ADDITIONAL_ENTITIES_COUNT } from '../../..//constants/benchmarks';
import { EntitySelectorModal } from '../../../components';
import { manageDrawer } from '../../../constants/messages/benchmarks';

type Props = {
  defaultItems: Entity[];
  onClose: () => void;
  onSave: (selectedItems: Entity[]) => void;
};

export const AdditionalCompetitorsModal = ({ defaultItems, onClose, onSave }: Props) => {
  const queryClient = useQueryClient();

  const [entities, setEntities] = useState<Entity[]>([]);
  const [searchKey, setSearchKey] = useState('');

  const { data, fetchNextPage, hasNextPage, isPending, isFetchingNextPage } =
    useInfiniteCompetitors(DEFAULT_PAGE_SIZE, DEFAULT_SORT_KEY, searchKey);

  const isLoading = isPending || isFetchingNextPage;

  useEffect(() => {
    setEntities(data?.pages.map((page) => page.data).flat() || []);
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_COMPETITORS] });
  };

  const renderRowContent = (entity: Entity) => (
    <ListItemText primary={entity.name} secondary={`${entity.city}, ${entity.country_name}`} />
  );

  return (
    <EntitySelectorModal
      items={entities}
      defaultItems={defaultItems}
      title={<FormattedMessage {...manageDrawer.additionalCompetitorsModalHeader} />}
      selectedHeaderTitle={(count) => (
        <FormattedMessage
          {...manageDrawer.additionalCompetitorsModalSelectedCount}
          values={{ count }}
        />
      )}
      subHeader={
        <FormattedMessage
          {...manageDrawer.additionalCompetitorsModalSubHeader}
          values={{ count: MAX_ADDITIONAL_ENTITIES_COUNT }}
        />
      }
      isOpen
      maxItems={MAX_ADDITIONAL_ENTITIES_COUNT}
      isLoading={isLoading}
      onClose={onClose}
      onSave={onSave}
      onSearch={onSearch}
      renderRowContent={renderRowContent}
      onFetchNextPage={onFetchNextPage}
    />
  );
};

import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSendImpactScore } from '@trustyou/analytics';
import { SISENSE_SERVER_PATH, useApplySecurityFilters } from '@trustyou/shared';
import { Stack, Typography } from '@trustyou/ui';

import FiltersLoader from './filters-loader';

import { newReportWizardMessages } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import styles from './styles';

export const ReportDashboard = () => {
  const { newReport, isDashboardLoaded, setIsDashboardLoaded } = useNewReportStore();
  const { applySecurityFilters } = useApplySecurityFilters();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const sisenseFrameRef = useRef<unknown>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { sendImpactScore } = useSendImpactScore(iFrameRef);

  // Navigate to previous page after back button
  const popEventHandler = () => {
    if (window.location.href.includes('/analytics') && !location.state?.path) {
      // Skip sisense jwt route on back button click
      navigate(-2);
    }
  };
  const messageEventHandler = (event: MessageEvent) => {
    if (!window.location.href.includes('/reports')) return;
    if (event.origin !== SISENSE_SERVER_PATH) return;
    sendImpactScore(event);
  };

  useEffect(() => {
    window.addEventListener('popstate', popEventHandler);
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('popstate', popEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newReport?.contentId]);

  const renderDashboard = () => {
    if (!newReport?.contentId) return;

    // TODO: Investigate why declaration.d.ts is not considered by current tsconfig
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { SisenseFrame } = window['sisense.embed'];

    // Create an instance of SisenseFrame
    const sisenseFrame = new SisenseFrame({
      url: SISENSE_SERVER_PATH,
      dashboard: newReport?.contentId,
      settings: {
        showToolbar: false,
        showLeftPane: false,
        showRightPane: true,
      },
      // Existing iFrame DOM element
      element: iFrameRef.current,
      // Disable the autosave of user interactions with the dashboard
      // volatile: true,
    });
    sisenseFrameRef.current = sisenseFrame;

    // Calling render() will apply the above configuration to the existing iFrame element
    sisenseFrame.render();

    // When dashboard is loaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sisenseFrame.dashboard.on('dashboardloaded', (args: any) => {
      // Push state to trigger pop state event and skip the empty page (jwt return_to)
      window.history.pushState(null, '', window.location.href);
      applySecurityFilters(args.dashboard.filters, sisenseFrame);
      setIsDashboardLoaded(true);
    });
  };

  return (
    <>
      {!isDashboardLoaded && <FiltersLoader />}
      <Stack sx={{ ...styles.dashboardContainer, ...(!isDashboardLoaded && { display: 'none' }) }}>
        {isDashboardLoaded && (
          <Typography variant="body1">
            <FormattedMessage {...newReportWizardMessages.filtersInfo} />
          </Typography>
        )}
        <iframe ref={iFrameRef} title="Sisense Embed" />
      </Stack>
    </>
  );
};

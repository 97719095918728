import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, Typography } from '@trustyou/ui';

import type { Content } from '../../../../types/content';
import { findOverallScoreQuestion } from '../../../../utils/survey-questionnaire-editor';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

export function EditorEmailInviteContent({ contents = [] }: { contents: Content[] }) {
  const { register, setValue, watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const emailInvitation = watch('surveyQuestionnaireEditorEmailInvitation');
  const emailInvitationId = emailInvitation?.id || contents?.[0].id || '';
  const embedOverallScore = emailInvitation?.embedOverallScore ?? false;

  useEffect(() => {
    register('surveyQuestionnaireEditorEmailInvitation');
    if (!emailInvitation) {
      setValue(
        'surveyQuestionnaireEditorEmailInvitation',
        contents?.filter((item) => item.is_default)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.email-invite.title"
            defaultMessage="Email invitation"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.email-invite.description"
            defaultMessage="Select an email template to use for the Survey invitations. These can be created and edited in the Content editor."
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        size="small"
        value={emailInvitationId}
        onChange={(e) => {
          const selectedEmailInvite = contents.find((item) => item.id === e.target.value);
          setValue('surveyQuestionnaireEditorEmailInvitation', {
            ...selectedEmailInvite,
            embedOverallScore,
          });
        }}
      >
        {contents.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.invitation_email?.subject?.[defaultSurveyLanguage] ??
              item.invitation_email?.subject?.[item.default_language ?? 'en'] ??
              'Email invitation'}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
      {findOverallScoreQuestion(watch('surveyContent')) && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={embedOverallScore}
                onChange={(e) => {
                  setValue('surveyQuestionnaireEditorEmailInvitation', {
                    ...emailInvitation,
                    embedOverallScore: e.target.checked,
                  });
                }}
              />
            }
            label={
              <FormattedMessage
                id="survey.survey-editor.email-invite.checkbox.label"
                defaultMessage="Embed the Overall Score question into the email"
              />
            }
          />
        </FormGroup>
      )}
    </Stack>
  );
}

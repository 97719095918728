import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { faBuildings, faListTree } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Alert, Box, SelectionCard, Typography } from '@trustyou/ui';

import styles from './styles';

type AccessOptionSelectionProps = {
  validated?: boolean;
  accessOption?: string;
  setAccessOption: (accessOption: string) => void;
};

export const AccessOptionSelection = ({
  validated,
  accessOption,
  setAccessOption,
}: AccessOptionSelectionProps) => {
  const intl = useIntl();
  const [isScopeByGroupsEnabled, setIsScopeByGroupsEnabled] = useState(false);

  const accessOptions = [
    {
      id: 'entities',
      title: intl.formatMessage({
        id: 'access-group.access-option.by-entities',
        defaultMessage: 'By entities',
      }),
      content: intl.formatMessage({
        id: 'access-group.access-option.by-entities.description',
        defaultMessage: 'Select entities in your organization',
      }),
      icon: faBuildings,
    },
    {
      id: 'segments',
      title: intl.formatMessage({
        id: 'access-group.access-option.by-segments',
        defaultMessage: 'By segments',
      }),
      content: intl.formatMessage({
        id: 'access-group.access-option.by-segments.description',
        defaultMessage: 'Select segment groups of entities (e.g. brand, city) in your organization',
      }),
      icon: faListTree,
    },
    {
      id: 'rules',
      title: intl.formatMessage({
        id: 'access-group.access-option.by-rules',
        defaultMessage: 'Advanced selection',
      }),
      content: intl.formatMessage({
        id: 'access-group.access-option.by-rules.description',
        defaultMessage: 'Access to entities based on rules',
      }),
      icon: faListTree,
    },
  ];

  // TODO: Remove this logic once all scopes by groups are migrated to advanced scope
  useEffect(() => {
    if (accessOption === 'segments') {
      setIsScopeByGroupsEnabled(true);
    } else if (!accessOption) {
      setIsScopeByGroupsEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessOption]);

  return (
    <Box sx={styles.accessOptionSelectionContainer}>
      <Typography variant="body1">
        <FormattedMessage
          id="access-group.choose-access-option"
          defaultMessage="How do you want to define this access group?"
        />
      </Typography>
      <Box sx={styles.accessOptionSelectionCardContainer}>
        {accessOptions
          .filter((option) => option.id !== 'segments' || isScopeByGroupsEnabled)
          .map((row) => (
            <SelectionCard
              {...row}
              key={row.id}
              title={row.title}
              content={row.content}
              onClick={() => setAccessOption(row.id)}
              selected={accessOption === row.id}
              iconAltText={row.title}
              sx={styles.accessOptionSelectionCard}
            />
          ))}
      </Box>
      {validated && !accessOption && (
        <Alert severity="error">
          <FormattedMessage
            id="access-group.select-access-option"
            defaultMessage="Select an access option"
          />
        </Alert>
      )}
    </Box>
  );
};

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl';

import { MenuItem, Stack, Typography } from '@trustyou/ui';

import type { Content } from '../../../../types/content';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';

const NoWelcomeMessage = (intl: IntlShape) => {
  return {
    id: 'no-welcome-message',
    title: {
      en: intl.formatMessage({
        id: 'survey.survey-editor.welcome.no-message-title',
        defaultMessage: 'No welcome message',
      }),
    },
    metadata: { organization_id: '' },
    internal_name: {},
    description: {},
    primitive: {},
  };
};

export function EditorWelcomeMessageContent({ contents = [] }: { contents: Content[] }) {
  const intl = useIntl();
  const { register, setValue, watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const [allWelcomeMessages, setAllWelcomeMessages] = useState(contents);

  const welcomeMessageId =
    watch('surveyQuestionnaireEditorWelcomeMessage')?.id || 'no-welcome-message';

  useEffect(() => {
    register('surveyQuestionnaireEditorWelcomeMessage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contents) {
      setAllWelcomeMessages([NoWelcomeMessage(intl), ...contents]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.welcome-message.title"
            defaultMessage="Welcome message"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.welcome-message.description"
            defaultMessage="Optional message that is displayed at the very beginning of the Survey. You can create and edit Welcome messages in the Content library."
          />
        </Typography>
      </Stack>
      <CustomTextFieldWithSeparatedLabel
        select
        size="small"
        value={welcomeMessageId}
        onChange={(e) => {
          const selectedMessage = allWelcomeMessages.find((item) => item.id === e.target.value);
          setValue('surveyQuestionnaireEditorWelcomeMessage', selectedMessage);
        }}
      >
        {allWelcomeMessages.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.id === 'no-welcome-message'
              ? item.title?.['en']
              : (item.title?.[defaultSurveyLanguage] ??
                item.title?.[item.default_language ?? 'en'] ??
                'Welcome text')}
          </MenuItem>
        ))}
      </CustomTextFieldWithSeparatedLabel>
    </Stack>
  );
}

import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { AccessGroup, PaginationRequest } from '@trustyou/shared';

import {
  CREATE_UPDATE_ACCESS_GROUP,
  DELETE_ACCESS_GROUPS,
  FETCH_ACCESS_GROUPS,
  FETCH_ACCESS_GROUP_DETAILS,
  FETCH_INFINITE_ACCESS_GROUPS,
  IS_VALID_ACCESS_GROUP,
} from '../../../constants';
import {
  createUpdateAccessGroup,
  deleteAccessGroup,
  fetchAccessGroupDetails,
  fetchAccessGroups,
  isValidAccessGroupName,
} from '../../../queries/domains/ass/accessGroup';

export function useAccessGroups(pagination: PaginationRequest, searchKey?: string) {
  return useQuery({
    queryKey: [FETCH_ACCESS_GROUPS, { pagination, searchKey }],
    queryFn: () => fetchAccessGroups(pagination, searchKey),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useInfiniteAccessGroups(pageSize: number, searchKey?: string) {
  return useInfiniteQuery({
    queryKey: [FETCH_INFINITE_ACCESS_GROUPS],
    queryFn: ({ pageParam }) =>
      fetchAccessGroups({ offset: pageParam, limit: pageSize }, searchKey),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
    placeholderData: keepPreviousData,
  });
}

export function useAccessGroupDetails(id?: string) {
  return useQuery({
    queryKey: [FETCH_ACCESS_GROUP_DETAILS, { id }],
    queryFn: () => fetchAccessGroupDetails(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
}

export function useCreateUpdateAccessGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createUpdateAccessGroup,
    mutationKey: [CREATE_UPDATE_ACCESS_GROUP],
    onSuccess: (accessGroup: AccessGroup) => {
      queryClient.removeQueries({ queryKey: [FETCH_ACCESS_GROUP_DETAILS, { id: accessGroup.id }] });
      queryClient.refetchQueries({ queryKey: [FETCH_ACCESS_GROUPS] });
      queryClient.refetchQueries({ queryKey: [FETCH_INFINITE_ACCESS_GROUPS] });
    },
  });
}

export function useDeleteAccessGroup() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAccessGroup,
    mutationKey: [DELETE_ACCESS_GROUPS],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ACCESS_GROUPS] });
    },
  });
}

export function useIsValidAccessGroup() {
  return useMutation({
    mutationFn: isValidAccessGroupName,
    mutationKey: [IS_VALID_ACCESS_GROUP],
  });
}

import { FormattedMessage, useIntl } from 'react-intl';

import { faMemoCircleInfo as faMemoCircleInfoOutlined } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faMemoCircleInfo as faMemoCircleInfoSolid } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { type Entity, useResponsive } from '@trustyou/shared';
import { Button, IconButton, Link, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import type { ReviewRoot } from '../../../types';
import { getDateFromReview } from '../../../utils/date';
import { Stamp } from '../../stamps';

const Separator = () => <Typography>·</Typography>;

export type MetaDataProps = {
  reviewRoot: ReviewRoot;
  entity: Entity;
  onPressGuestIcon?: () => void;
};

export function ReviewMetadata({ reviewRoot, entity, onPressGuestIcon }: MetaDataProps) {
  const intl = useIntl();
  const { isPhone } = useResponsive();

  return (
    <Stack
      sx={{
        paddingBlock: 1,
        paddingInline: 2,
        gap: 1,
        alignItems: 'start',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: (theme) => theme.palette.grey[50],
      }}
    >
      <Stack
        direction="row"
        sx={{
          columnGap: 3,
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isPhone && {
            width: '100%',
            justifyContent: 'space-between',
          }),
        }}
      >
        <Typography variant="h6" sx={{ fontSize: 'initial' }}>
          {reviewRoot?.review?.author}
        </Typography>
        {isPhone ? (
          <IconButton color="primary" onClick={onPressGuestIcon}>
            <StyledFontAwesomeIcon icon={faMemoCircleInfoOutlined} />
          </IconButton>
        ) : (
          <Button
            onClick={onPressGuestIcon}
            startIcon={<StyledFontAwesomeIcon icon={faMemoCircleInfoSolid} />}
          >
            <FormattedMessage id="inbox.meta.additional-info" defaultMessage="Additional info" />
          </Button>
        )}
      </Stack>
      <Stack spacing={0.5}>
        <Stack
          sx={{
            columnGap: 1,
            flexWrap: 'wrap',
            ...(!isPhone && {
              flexDirection: 'row',
              alignItems: 'center',
            }),
          }}
        >
          <Stamp
            status={
              <FormattedMessage
                id="inbox.published-with-date"
                defaultMessage="Published: {publishDate}"
                values={{ publishDate: getDateFromReview(reviewRoot?.review?.date ?? '') }}
              />
            }
            tooltip={intl.formatMessage({
              id: 'inbox.published-with-date-tooltip',
              defaultMessage: 'Review published on the source site',
            })}
          />
          {!isPhone && <Separator />}
          <Stamp
            status={
              <FormattedMessage
                id="inbox.processed-with-date"
                defaultMessage="Processed: {processedDate}"
                values={{
                  processedDate: getDateFromReview(reviewRoot?.review?.produced_at ?? ''),
                }}
              />
            }
            tooltip={intl.formatMessage({
              id: 'inbox.processed-with-date-tooltip',
              defaultMessage: 'Review processed by this Inbox',
            })}
          />
        </Stack>
        <Stack
          sx={{
            columnGap: 1,
            flexWrap: 'wrap',
            ...(!isPhone && {
              flexDirection: 'row',
              alignItems: 'center',
            }),
          }}
        >
          <Typography variant="caption" color="text.secondary">
            <FormattedMessage
              id="inbox.meta.source"
              defaultMessage="Source: {source}"
              values={{
                source: (
                  <Link
                    href={reviewRoot?.review?.url ?? ''}
                    target="_blank"
                    rel="noreferrer"
                    underline="hover"
                    variant="caption"
                  >
                    {reviewRoot?.survey?.survey_name
                      ? `TrustYou Survey: ${reviewRoot?.survey.survey_name}`
                      : (reviewRoot?.review?.domain_name ?? reviewRoot?.review?.domain_id)}
                  </Link>
                ),
              }}
            />
          </Typography>
          {!isPhone && <Separator />}
          <Typography variant="caption" color="text.secondary">
            <FormattedMessage
              id="inbox.review.meta.entity"
              defaultMessage="Entity: {entity}"
              values={{
                entity: (
                  <Typography color="text.primary" variant="caption">
                    {entity?.name}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

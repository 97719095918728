import type { FormState, UseFormRegister } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Box, TextField, Typography } from '@trustyou/ui';

import styles from './styles';

export type UserEmailForm = {
  email: string;
};

type UserEmailProps = {
  register: UseFormRegister<UserEmailForm>;
  formState: FormState<UserEmailForm>;
  disabled?: boolean;
};

const UserEmail = ({ register, formState, disabled }: UserEmailProps) => (
  <Box sx={styles.emailContainer}>
    <Typography variant="subtitle1">
      <FormattedMessage
        id="organization.users.enterEmailAddress"
        defaultMessage="Enter email address"
      />
    </Typography>
    <FormattedMessage id="organization.users.emailAddress" defaultMessage="Email address">
      {(message) => (
        <TextField
          data-testid="user-email"
          label={message}
          variant="standard"
          {...register('email')}
          error={!!formState.errors.email}
          helperText={formState.errors.email?.message}
          disabled={disabled}
        />
      )}
    </FormattedMessage>
  </Box>
);

export default UserEmail;

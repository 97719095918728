import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  type AccessGroupCreateUpdatePayload,
  getAccessGroupValidationSchema,
  getParsedScopeRules,
  useAccessGroupDetails,
  useBasicEntities,
  useCreateUpdateAccessGroup,
  useIsValidAccessGroup,
  useRouteUtils,
  useScopeStore,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  ContentPane,
  DiscardChangesModal,
  Typography,
  snackbar,
} from '@trustyou/ui';

import AccessGroupName, { type AccessGroupNameForm } from './AccessGroupName';
import { AccessOptionSelection } from './AccessOptionSelection';
import { EntitySelection } from './EntitySelection';
import { Footer } from './Footer';
import { GroupSelection } from './GroupSelection';

import { AdvancedScopeSelection } from '../../../../../components';
import styles from './styles';

type CreateUpdateAccessGroupProps = {
  onLeave?: () => void;
};

export function CreateUpdateAccessGroup({ onLeave }: CreateUpdateAccessGroupProps) {
  const intl = useIntl();
  const isModal = !!onLeave;
  const { goUp } = useRouteUtils();
  const { accessGroupId } = useParams();
  const {
    selectedEntities,
    selectedGroups,
    setSelectedEntities,
    setSelectedGroups,
    isValidAdvancedSelectionScope,
    getSavingAdvancedSelectionScope,
    setAdvancedSelectionScope,
  } = useScopeStore();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedAccessOption, setSelectedAccessOption] = useState<string>();
  const isValidAccessGroup = useIsValidAccessGroup();
  const { data: accessGroup, isFetching: isLoadingAccessGroup } =
    useAccessGroupDetails(accessGroupId);
  const scopeAdditionalEntityIds = accessGroup?.mel?.additional_entities || [];

  const { data: scopeAdditionalEntities = [] } = useBasicEntities(
    scopeAdditionalEntityIds,
    !!scopeAdditionalEntityIds.length
  );

  const editMode = accessGroupId !== undefined;

  const schema = getAccessGroupValidationSchema(
    intl,
    isValidAccessGroup.mutateAsync,
    accessGroup?.name
  );
  const { register, trigger, formState, getValues, setValue } = useForm<AccessGroupNameForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: accessGroupId && (accessGroup?.name || '_'),
    },
  });

  const createUpdateAccessGroup = useCreateUpdateAccessGroup();
  const isSaving = createUpdateAccessGroup.isPending;

  const validateForm = async () => {
    const isFormValid = !!accessGroup || (await trigger());
    if (
      !isFormValid ||
      !selectedAccessOption ||
      (selectedAccessOption === 'entities' && !selectedEntities.length) ||
      (selectedAccessOption === 'segments' && !selectedGroups.length) ||
      (selectedAccessOption === 'rules' && !isValidAdvancedSelectionScope())
    ) {
      return false;
    }
    return true;
  };

  const onSave = async () => {
    const isFormValid = await validateForm();
    setValidated(true);
    if (!isFormValid) {
      return;
    }
    const savingAccessGroup: AccessGroupCreateUpdatePayload = {
      all: false,
      name: getValues('name'),
      entity_ids:
        selectedAccessOption === 'entities' ? selectedEntities.map((entity) => entity.id) : [],
      group_ids: selectedAccessOption === 'segments' ? selectedGroups.map((group) => group.id) : [],
      mel: selectedAccessOption === 'rules' ? getSavingAdvancedSelectionScope() : null,
    };
    if (accessGroupId) {
      savingAccessGroup.id = accessGroupId;
    }
    createUpdateAccessGroup.mutate(savingAccessGroup, {
      onSuccess: () => {
        cancelSave();
        snackbar.success(
          intl.formatMessage({
            id: 'organization.users.changesSaved',
            defaultMessage: 'Changes saved',
          })
        );
      },
    });
  };

  const handleCancel = () => {
    if (
      !accessGroup &&
      (getValues('name') ||
        selectedAccessOption ||
        selectedGroups.length ||
        selectedEntities.length)
    ) {
      setShowCancelConfirmation(true);
    } else {
      cancelSave();
    }
  };

  const cancelSave = () => {
    if (isModal) {
      onLeave();
    } else {
      goUp(editMode ? 2 : 1);
    }
  };

  useEffect(() => {
    setAdvancedSelectionScope();
    if (accessGroup?.name) {
      setValue('name', accessGroup?.name);
    }
    if (accessGroup?.entities.length) {
      setSelectedAccessOption('entities');
    } else if (accessGroup?.groups.length) {
      setSelectedAccessOption('segments');
    } else if (accessGroup?.mel) {
      setSelectedAccessOption('rules');
      setAdvancedSelectionScope({
        rules: getParsedScopeRules(accessGroup.mel.rule),
        additional_entities: accessGroup.mel.additional_entities.map((id) => ({
          id,
          //These values will be filled with scopeAdditionalEntities data
          name: '',
          country_name: '',
          city: '',
        })),
      });
    }
    setSelectedEntities(accessGroup?.entities || []);
    setSelectedGroups(accessGroup?.groups || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGroup]);

  useEffect(() => {
    if (accessGroup?.mel?.additional_entities.length && scopeAdditionalEntities.length) {
      setAdvancedSelectionScope({
        rules: getParsedScopeRules(accessGroup.mel.rule),
        additional_entities: scopeAdditionalEntities,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeAdditionalEntities]);

  return (
    <ContentPane
      headers={[
        <Typography variant="h6">
          {editMode ? (
            <FormattedMessage id="access-group.edit-details" defaultMessage="Edit details" />
          ) : (
            <FormattedMessage
              id="access-group.add-access-group"
              defaultMessage="Add access group"
            />
          )}
        </Typography>,
      ]}
      navigationHandle={!isModal ? handleCancel : undefined}
      headerStyles={isModal ? { pl: 3 } : {}}
      sx={styles.contentPane}
      bodyStyles={styles.contentPaneBody}
    >
      <Box sx={styles.body}>
        <AccessGroupName register={register} formState={formState} />
        <AccessOptionSelection
          accessOption={selectedAccessOption}
          setAccessOption={setSelectedAccessOption}
          validated={validated}
        />
        {selectedAccessOption === 'entities' && <EntitySelection validated={validated} />}
        {selectedAccessOption === 'segments' && <GroupSelection validated={validated} />}
        {selectedAccessOption === 'rules' && <AdvancedScopeSelection validated={validated} />}
      </Box>
      <Footer actionMode={editMode ? 'update' : 'create'} onCancel={handleCancel} onSave={onSave} />
      <DiscardChangesModal
        onClose={() => {
          setShowCancelConfirmation(false);
        }}
        onDiscard={cancelSave}
        open={showCancelConfirmation}
      />
      <BackdropSpinner isLoading={isSaving || isLoadingAccessGroup} />
    </ContentPane>
  );
}

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  IconButton,
  type IconButtonProps,
  StyledFontAwesomeIcon,
  TyLogo,
  type TyLogoProps,
  Typography,
} from '@trustyou/ui';

import type { StyleUnit } from '../../types/common';

export interface DrawerHeaderProps {
  logoVariant?: TyLogoProps['variant'];
  onClose?: IconButtonProps['onClick'];
  sx?: StyleUnit;
}

export const DrawerHeader = ({ logoVariant = 'blue', onClose, sx }: DrawerHeaderProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      padding: 2,
      ...sx,
    }}
  >
    <TyLogo variant={logoVariant} />
    <Typography variant="body1">TrustYou</Typography>
    <Box sx={{ flexGrow: 1 }} />
    {onClose && (
      <IconButton title="close drawer" onClick={onClose}>
        <StyledFontAwesomeIcon icon={faXmark} size="sm" />
      </IconButton>
    )}
  </Box>
);

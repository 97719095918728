import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { lightBlue } from '@mui/material/colors';
import {
  faEdit,
  faGripDotsVertical,
  faTrashCan,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { capitalizeFirstLetter } from '@trustyou/shared';
import { Box, Card, IconButton, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import type { SurveyQuestionnaireEditorContentOutput } from '../../../../../types/survey';
import { MAX_CARD_WIDTH_PX } from '../../../../feedback/constants';
import { EDITOR_QUESTION_CONTENT_TYPES } from '../../../constants/survey-editor-content';

type ContentItemProps = {
  content: SurveyQuestionnaireEditorContentOutput;
  type?: 'question' | 'section' | 'welcome_message' | 'email_invitation' | 'thankyou_message';
  isQuestionInsideSection?: boolean;
  isSubmitSection?: boolean;
  onDelete?: () => void;
  onEditSection?: () => void;
  children?: ReactNode;
};

export const ContentItem = ({
  content,
  type = 'question',
  isQuestionInsideSection = false,
  onDelete,
  isSubmitSection,
  onEditSection,
  children,
}: ContentItemProps) => {
  const { watch } = useFormContext();
  const defaultSurveyLanguage = watch('surveyEditorDefaultLanguage');
  const contentDefaultLang = content.default_language ?? 'en';
  const sideBorder =
    type === 'section'
      ? EDITOR_QUESTION_CONTENT_TYPES[content.type ?? 'default'].color
      : EDITOR_QUESTION_CONTENT_TYPES[content.primitive?.repr ?? 'default'].color;

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: MAX_CARD_WIDTH_PX,
        ...(!isQuestionInsideSection && {
          borderLeftWidth: 6,
          borderLeftColor: sideBorder,
        }),
        ...(isQuestionInsideSection && {
          borderWidth: 0,
        }),
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBlock: 2,
          paddingInline: 1,
          backgroundColor: type === 'section' ? lightBlue[50] : null,
        }}
      >
        <Stack flexDirection="row">
          <IconButton disabled={isSubmitSection}>
            {!isSubmitSection && <StyledFontAwesomeIcon icon={faGripDotsVertical} />}
          </IconButton>
          <Box>
            <Typography variant="subtitle1">
              {capitalizeFirstLetter(
                type === 'section'
                  ? (content?.title?.[defaultSurveyLanguage] ??
                      content?.title?.[contentDefaultLang])
                  : (content?.internal_name?.[defaultSurveyLanguage] ??
                      content?.internal_name?.[contentDefaultLang] ??
                      '')
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {type === 'section'
                ? (content?.description?.[defaultSurveyLanguage] ??
                  content?.description?.[contentDefaultLang])
                : (content?.title?.[defaultSurveyLanguage] ??
                  content?.title?.[contentDefaultLang] ??
                  '')}
            </Typography>
          </Box>
        </Stack>
        <Stack sx={{ flexDirection: 'row' }}>
          {type === 'section' && (
            <IconButton onClick={onEditSection}>
              <StyledFontAwesomeIcon icon={faEdit} />
            </IconButton>
          )}
          {onDelete && (
            <IconButton onClick={onDelete}>
              <StyledFontAwesomeIcon icon={faTrashCan} />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {children}
    </Card>
  );
};

import { useIntl } from 'react-intl';

import { FiltersFormFields } from '../../../types';
import { DropdownMultiple } from '../dropdown-multiple';

export type DropdownStatusProps = {
  initialValue: string[];
};

export const DropdownStatus = ({ initialValue }: DropdownStatusProps) => {
  const intl = useIntl();

  const statusOptions = [
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.unresponded',
        defaultMessage: 'Unresponded',
      }),
      children: [
        {
          label: intl.formatMessage({ id: 'inbox.filter.status.unread', defaultMessage: 'Unread' }),
          value: 'unread',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.read',
            defaultMessage: 'Read',
          }),
          value: 'read',
        },
      ],
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.responded',
        defaultMessage: 'Marked as responded',
      }),
      value: 'responded',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.confirmed',
        defaultMessage: 'Response confirmed',
      }),
      value: 'confirmed',
    },
    {
      label: intl.formatMessage({
        id: 'inbox.filter.status.moderated',
        defaultMessage: 'Moderated',
      }),
      children: [
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.marked-as-deleted',
            defaultMessage: 'Marked as deleted',
          }),
          value: 'marked_as_deleted',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.deleted',
            defaultMessage: 'Deleted',
          }),
          value: 'deleted',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.marked-as-inappropriate',
            defaultMessage: 'Marked as inappropriate',
          }),
          value: 'marked_as_inappropriate',
        },
        {
          label: intl.formatMessage({
            id: 'inbox.filter.status.inappropriate',
            defaultMessage: 'Inappropriate',
          }),
          value: 'inappropriate',
        },
      ],
    },
  ];
  return (
    <DropdownMultiple
      fieldName={FiltersFormFields.Status}
      options={statusOptions}
      allValuesLabel={intl.formatMessage({
        id: 'inbox.filter.status.all',
        defaultMessage: 'All Statuses',
      })}
      dataTestId="filterFieldStatus"
      label={intl.formatMessage({
        id: 'inbox.filter.status.label',
        defaultMessage: 'Status',
      })}
      initialSelectedValues={initialValue}
      showLabelAsResult
      size="small"
      fullWidth
    />
  );
};

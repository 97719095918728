import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  commonFields,
  supportedLanguages,
  useFetchUserSettings,
  useLanguageStore,
  useUpdateUserSettings,
} from '@trustyou/shared';
import { Box, Button, ContentPane, DiscardChangesModal, Typography, snackbar } from '@trustyou/ui';
import * as yup from 'yup';

import { LanguageSwitcher } from '../../../../components';
import { styles } from '../../styles';

const formSchema = yup.object().shape({
  language: yup.string(),
});

type FormSchema = {
  language: string;
};

export default function LanguageChange() {
  const intl = useIntl();
  const { locale, updateLocale } = useLanguageStore();

  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);

  const methods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      language: locale,
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const language = watch('language');

  const { data, isPending, refetch } = useFetchUserSettings();
  const updateUserSettings = useUpdateUserSettings();

  const previousLanguage = data?.settings?.language;

  const onSubmit = (formData: FormSchema) => {
    updateLocale(formData.language);
    updateUserSettings.mutate(
      {
        settings: {
          signature: data?.settings.signature || '',
          language: formData.language,
        },
      },
      {
        onSuccess: () => {
          snackbar.success(intl.formatMessage(commonFields.changesSaved));
          refetch();
        },
      }
    );
  };

  const handleCancel = () => {
    if (previousLanguage !== language) {
      setIsDiscardDialogOpen(true);
    }
  };

  const isLoading = isPending || updateUserSettings.isPending;

  return (
    <ContentPane bodyStyles={{ justifyContent: 'space-between' }}>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Typography variant="h6" color="text.primary">
              <FormattedMessage {...commonFields.language} />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage
                id="user.settings.language.description"
                defaultMessage="Choose your preferred language to be used in the application"
              />
            </Typography>
          </Box>
          <Box mt={5}>
            <LanguageSwitcher languages={supportedLanguages} />
          </Box>
          <Box sx={styles.pageActions}>
            <Button
              color="primary"
              variant="text"
              disabled={previousLanguage === language || isLoading}
              onClick={handleCancel}
              sx={{ marginRight: 2 }}
            >
              <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              data-testid="save-changes-language"
              disabled={previousLanguage === language || isLoading}
            >
              <FormattedMessage {...commonFields.saveChanges} />
            </Button>
          </Box>
        </Box>
        <DiscardChangesModal
          onClose={() => {
            setIsDiscardDialogOpen(false);
          }}
          onDiscard={() => {
            setValue('language', previousLanguage || locale);
            setIsDiscardDialogOpen(false);
          }}
          open={isDiscardDialogOpen}
        />
      </FormProvider>
    </ContentPane>
  );
}

import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@trustyou/ui';

export const PaginationCard = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <Card variant="outlined">
      <CardContent>
        <FormControl>
          <FormLabel sx={{ color: 'text.primary', fontWeight: 'bold' }}>
            <FormattedMessage
              id="survey.survey-editor.content.pagination.label"
              defaultMessage="How would you like to display the survey?"
            />
          </FormLabel>
          <Controller
            name="surveyQuestionnaireEditorPaginationOption"
            control={control}
            defaultValue="onePage"
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              >
                <FormControlLabel
                  value="onePage"
                  control={<Radio />}
                  label={
                    <FormattedMessage
                      id="survey.survey-editor.content.pagination.one-page.label"
                      defaultMessage="All questions on one page"
                    />
                  }
                />
                <FormControlLabel
                  value="multiplePage"
                  control={<Radio />}
                  label={
                    <FormattedMessage
                      id="survey.survey-editor.content.pagination.multiple-page.label"
                      defaultMessage="Split questionnaire into multiple pages"
                    />
                  }
                />
              </RadioGroup>
            )}
          />
          <Stack sx={{ flexDirection: 'row', gap: 1, justifyContent: 'center' }}>
            <Typography variant="body1">
              <FormattedMessage
                id="survey.survey-editor.content.pagination.questions-per-page"
                defaultMessage="Questions per page"
              />
            </Typography>
            <TextField
              size="small"
              defaultValue={5}
              sx={{ width: 70 }}
              error={!!errors?.surveyQuestionnaireEditorPaginationMultiplePageNumber}
              helperText={
                !!errors?.surveyQuestionnaireEditorPaginationMultiplePageNumber && (
                  <FormattedMessage
                    id="survey.common.enter-number"
                    defaultMessage="Enter a number"
                  />
                )
              }
              {...register('surveyQuestionnaireEditorPaginationMultiplePageNumber', {
                required: watch('surveyQuestionnaireEditorPaginationOption') === 'multiplePage',
              })}
            />
          </Stack>
        </FormControl>
      </CardContent>
    </Card>
  );
};

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useEntity } from '@trustyou/shared';
import { Box, CircularProgress, Paper, Stack, Typography } from '@trustyou/ui';

import { ResponseSide } from '../../components/review-detail/response-side/response-side';
import { ReviewSide } from '../../components/review-detail/review-side/review-side';
import { ReviewDetailToolbar } from '../../components/review-detail/toolbar';
import {
  updateReviewStatusById,
  useReview,
  useSetReviewReadStatus,
  useTranslatedReview,
} from '../../hooks';
import useSetReviewsStatusPermission from '../../hooks/permissions/use-set-reviews-status-permission';
import { ReviewRoot } from '../../types';

export function Review() {
  const queryClient = useQueryClient();
  const { reviewId = '' } = useParams();

  const setReviewStatus = useSetReviewReadStatus();
  const isSetReviewsStatusAllowed = useSetReviewsStatusPermission();

  const [shouldDisplayTranslation, setShouldDisplayTranslation] = useState(false);

  const reviewQuery = useReview({ reviewId });
  useEffect(() => {
    if (
      reviewQuery.data &&
      reviewQuery.data?.meta.status === 'unread' &&
      isSetReviewsStatusAllowed
    ) {
      setReviewStatus.mutate(
        { status: 'read', reviews: [reviewQuery.data.review.id] },
        {
          onSuccess: () => {
            updateReviewStatusById(queryClient, reviewQuery.data?.review.id, 'read');
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewQuery.data]);

  const translatedReviewQuery = useTranslatedReview({ reviewId, shouldDisplayTranslation });

  const entityQuery = useEntity(reviewQuery.data?.meta?.entity_id ?? '');

  if (entityQuery.isError || reviewQuery.isError || translatedReviewQuery.isError) {
    return (
      <Typography width={300} textAlign="center" margin="auto" marginTop={0}>
        <FormattedMessage
          defaultMessage="Something didn't quite go as planned. Please, give the page a quick refresh. If that doesn't work, no worries — just come back and try again a bit later"
          id="inbox.review.loadError"
        />
      </Typography>
    );
  }

  if (entityQuery.isLoading || reviewQuery.isLoading || translatedReviewQuery.isLoading) {
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  const reviewData = (
    shouldDisplayTranslation ? translatedReviewQuery.data : reviewQuery.data
  ) as ReviewRoot;

  return (
    <Paper
      elevation={0}
      sx={{
        margin: -3,
        height: '100%',
      }}
    >
      <ReviewDetailToolbar
        reviewData={reviewData}
        shouldDisplayTranslation={shouldDisplayTranslation}
        setShouldDisplayTranslation={setShouldDisplayTranslation}
      />
      <Box
        sx={{
          // Manual height adjustments to fit the scrollbar in the available space.
          height: { xs: 'calc(100% - 32px)', md: 'calc(100% - 8px)' },
          overflowY: 'auto',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        }}
      >
        <ReviewSide reviewData={reviewData} shouldDisplayTranslation={shouldDisplayTranslation} />
        <ResponseSide />
      </Box>
    </Paper>
  );
}

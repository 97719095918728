const openAssistant = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Userlane('openAssistant');
};

type UserData = {
  userRole?: string;
  productPlan?: string;
  breakpoint: string;
};

export const initUserlane = (userId: string, userData: UserData, lang: string) => {
  sendUserlaneData(userId, userData);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Userlane('init', process.env.REACT_APP_USERLANE_ID);
  changeUserlaneLanguage(lang);
};

export const openFeatureTour = () => {
  openAssistant();
  setTimeout(() => {
    document.getElementById('usln-p-assistant-nav-tab__chapters')?.click();
  }, 10);
};

export const openNotifications = () => {
  openAssistant();
  setTimeout(() => {
    document.getElementById('usln-p-assistant-nav-tab__announcement-list')?.click();
  }, 10);
};

export const changeUserlaneLanguage = (lang: string) => {
  if (!lang) return;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.Userlane) Userlane('lang', lang.toLowerCase());
};

export const sendUserlaneData = (userId: string, userData: UserData) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.Userlane) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Userlane('identify', userId, {
      user_role: userData.userRole,
      product_plan: userData.productPlan,
      breakpoint: userData.breakpoint,
    });
  }
};

import type { MouseEvent, ReactNode } from 'react';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { SxProps, Theme, TooltipProps } from '@mui/material';
import {
  Button,
  type ButtonProps,
  ICON_BUTTON_HEIGHT,
  ICON_BUTTON_WIDTH,
  ICON_HEIGHT,
  ICON_WIDTH,
  NAV_ICON_BACKGROUND_COLOR,
  StyledFontAwesomeIcon,
  Tooltip,
} from '@trustyou/ui';

export type NavIconProps = ButtonProps & {
  id?: string;
  icon: IconDefinition;
  tooltip?: ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  altText?: string;
  selected?: boolean;
  shape?: 'rounded' | 'circular';
  onClick: (event: MouseEvent) => void;
  sx?: SxProps<Theme>;
};

export const NavIcon = ({
  id,
  icon,
  tooltip,
  tooltipPlacement = 'right',
  selected = false,
  shape = 'rounded',
  altText = 'hidden',
  onClick,
  sx,
  ...props
}: NavIconProps) => (
  <Tooltip placement={tooltipPlacement} arrow title={tooltip}>
    <Button
      data-testid={`${id}-nav-icon`}
      data-gtm-id={`${id}-nav-icon`}
      onClick={onClick}
      sx={{
        height: ICON_BUTTON_HEIGHT,
        width: ICON_BUTTON_WIDTH,
        minWidth: 0,
        color: 'action.active',
        ...(shape === 'circular' && {
          borderRadius: '50%',
        }),
        ...(selected && {
          color: 'primary.main',
          backgroundColor: NAV_ICON_BACKGROUND_COLOR,
          '&:hover': {
            color: 'primary.main',
            backgroundColor: NAV_ICON_BACKGROUND_COLOR,
          },
        }),
        ...sx,
      }}
      {...props}
    >
      <StyledFontAwesomeIcon
        icon={icon}
        title={altText}
        sx={{
          height: ICON_HEIGHT,
          width: ICON_WIDTH,
        }}
      />
    </Button>
  </Tooltip>
);

import { Fragment, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { faBuilding, faBuildings } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type Membership,
  type MembershipFromToken,
  type OrganizationWithMemberships,
  USER_ROLES,
  roleDefinitions,
} from '@trustyou/shared';
import {
  Box,
  Chip,
  Divider,
  StyledFontAwesomeIcon as Icon,
  List,
  ListItem,
  Stack,
  Typography,
} from '@trustyou/ui';

import { redirectToOriginalMembership } from '../../utils/misc';
import styles from './styles';

type MembershipSelectorProps = {
  selectedMembership?: string;
  defaultLogo?: string;
  organizations: OrganizationWithMemberships[];
  onSelect: (membership: MembershipFromToken) => void;
};

const MembershipSelector = ({
  organizations,
  selectedMembership,
  defaultLogo,
  onSelect,
}: MembershipSelectorProps) => {
  const selectedRef = useRef<HTMLLIElement | null>(null);
  const filterMemberships = (memberships: Membership[]) => {
    const hasOrgSubscriptionOwner = !!memberships.find(
      (row) => row.role === USER_ROLES.OWNER && row.subscription.is_org
    );
    // Hide owner-role memberships of entity subscriptions if the user has a owner-role membership with org subscription.
    if (hasOrgSubscriptionOwner) {
      return memberships.filter(
        (row) => !(row.role === USER_ROLES.OWNER && !row.subscription.is_org)
      );
    }
    return memberships;
  };

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <List sx={styles.list}>
      {organizations.map(
        ({ id: organizationId, name: organizationName, logo_uri: logoUri, memberships }, index) => (
          <Fragment key={organizationId}>
            {index > 0 && <Divider sx={styles.divider} />}
            <Stack
              sx={{
                ...styles.orgLogoTitleWrapper,
                ...(logoUri && styles.orgLogoTitleMinHeight),
              }}
            >
              {(logoUri || defaultLogo) && (
                <Box component="img" src={logoUri || defaultLogo} sx={styles.orgLogo} />
              )}
              <Typography variant="subtitle1">{organizationName}</Typography>
            </Stack>
            <List sx={styles.list}>
              {filterMemberships(memberships).map(({ id, subscription, scope, role }) => {
                const singleEntity =
                  scope && scope.entities?.length === 1 ? scope.entities[0] : undefined;
                const secondaryText = singleEntity
                  ? `${singleEntity.city}, ${singleEntity.country_name}`
                  : subscription.plan;
                const isSelected = selectedMembership === id;
                return (
                  <ListItem
                    key={id}
                    ref={isSelected ? selectedRef : null}
                    sx={{ ...styles.listItem, ...(isSelected && styles.listItemSelected) }}
                    onClick={(event) => {
                      if (event.metaKey || event.ctrlKey) {
                        redirectToOriginalMembership(id, { silent: true, isNewTab: true });
                      } else {
                        onSelect({ id, organizationId, subscriptionId: subscription.id });
                      }
                    }}
                  >
                    <Icon
                      size="lg"
                      icon={singleEntity ? faBuilding : faBuildings}
                      sx={styles.orgIcon}
                    />
                    <Box sx={styles.listItemInner}>
                      <Typography variant="subtitle2">
                        {singleEntity?.name || subscription.name}
                      </Typography>
                      {secondaryText && (
                        <Typography variant="body2" sx={styles.plan}>
                          {secondaryText}
                        </Typography>
                      )}
                    </Box>
                    {(role === USER_ROLES.ADMIN || role === USER_ROLES.OWNER) && (
                      <Chip
                        label={<FormattedMessage {...roleDefinitions[role]} />}
                        size="small"
                        sx={styles.roleChip}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Fragment>
        )
      )}
    </List>
  );
};

export default MembershipSelector;

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { faAnglesUpDown } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useMembershipStore } from '@trustyou/shared';
import { Box, StyledFontAwesomeIcon as Icon, PopperMenu, Typography } from '@trustyou/ui';

import { OrganizationsMenu } from '../../Nav/OrganizationsMenu/OrganizationsMenu';
import styles from './styles';

export const MobileUserMenu = () => {
  const { accessTokenPayload } = useOidcAccessToken();
  const { getMembershipDetails, getMembershipsCount } = useMembershipStore();
  const subscription = getMembershipDetails()?.subscription;
  const isMultiOrg = (getMembershipsCount() ?? 0) > 1;
  return (
    <PopperMenu
      // eslint-disable-next-line react/no-unstable-nested-components
      Trigger={({ onClick }) => (
        <Box title="open user menu" onClick={isMultiOrg && onClick} sx={styles.header}>
          <Box>
            <Typography variant="body1">{subscription?.name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {accessTokenPayload.email}
            </Typography>
          </Box>
          {isMultiOrg && <Icon icon={faAnglesUpDown} />}
        </Box>
      )}
      Content={OrganizationsMenu}
      popperStyle={styles.popper}
    />
  );
};

import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  FETCH_ENTITY_DETAILS,
  FETCH_INFINITE_COMPETITORS,
  SET_ENTITY_COMPETITORS,
} from '../../../constants/queryKeys';
import { fetchCompetitors, setEntityCompetitors } from '../../../queries';

export function useInfiniteCompetitors(pageSize: number, sortKey: string, searchKey?: string) {
  return useInfiniteQuery({
    queryKey: [FETCH_INFINITE_COMPETITORS, { limit: pageSize, sortKey, searchKey }],
    queryFn: ({ pageParam = 0 }) =>
      fetchCompetitors({ offset: pageParam, limit: pageSize }, sortKey, searchKey),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    enabled: true,
    getNextPageParam: (lastPage) => {
      const newOffset = lastPage.pagination.offset + pageSize;
      return newOffset < lastPage.pagination.total ? newOffset : undefined;
    },
  });
}

export function useSetEntityCompetitors() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setEntityCompetitors,
    mutationKey: [SET_ENTITY_COMPETITORS],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [FETCH_ENTITY_DETAILS] });
    },
  });
}

import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReportsDrawer, useExportDashboardHandle } from '@trustyou/analytics';
// FIXME: Circular dependency: eslint(@nx/enforce-module-boundaries)
// eslint-disable-next-line
import { useHasSchedulePermission } from '@trustyou/reports';
import {
  SISENSE_SERVER_PATH,
  trackDashboardLoading,
  useApplySecurityFilters,
  useDashboardId,
} from '@trustyou/shared';
import { useSurveys } from '@trustyou/survey-manager';
import { Box, DashboardLoader } from '@trustyou/ui';

import { EmptyState } from './EmptyState';

import styles from './styles';

type DashboardProps = { show?: boolean; isChangeling?: boolean };

export const Dashboard = ({ show = true, isChangeling }: DashboardProps) => {
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  const [showReports, setShowReports] = useState<string>();
  const hasSchedulePermission = useHasSchedulePermission();
  const { data: dashboardIdResponse } = useDashboardId();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const sisenseFrameRef = useRef<unknown>(null);
  const loadingStartTimeRef = useRef<unknown>(null);
  const { data: surveys = [], isFetched: isSurveysLoaded } = useSurveys();

  const navigate = useNavigate();
  const location = useLocation();
  const { exportDashboard } = useExportDashboardHandle();
  const { applySecurityFilters } = useApplySecurityFilters();

  const trackLoadingStart = () => {
    if (!loadingStartTimeRef.current) {
      loadingStartTimeRef.current = new Date();
    }
  };

  const trackLoadingEnd = (id: string, title: string) => {
    trackDashboardLoading(id, title, loadingStartTimeRef.current as Date);
    loadingStartTimeRef.current = null;
  };

  // Navigate to previous page after back button
  const popEventHandler = () => {
    if (window.location.href.includes('/survey-insights') && !location.state?.path) {
      // Skip sisense jwt route on back button click
      navigate(-2);
    }
  };

  const messageEventHandler = (event: MessageEvent) => {
    if (!window.location.href.includes('/survey-insights')) return;
    if (event.origin !== SISENSE_SERVER_PATH || typeof event.data !== 'string') return;
    if (event.data.includes('dashboardAction')) {
      const split = event.data.split('-');
      const action = split[1];
      const dashboardId = split[2];
      if (action === 'reports') {
        setShowReports(dashboardId);
      } else if (action === 'downloadExcel') {
        exportDashboard(dashboardId, iFrameRef);
      }
    }
  };

  // Navigate to previous page after back button
  useEffect(() => {
    window.addEventListener('popstate', popEventHandler);
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('popstate', popEventHandler);
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardIdResponse?.survey_insights_id, surveys.length]);

  const enableDashboardActions = () => {
    iFrameRef.current?.contentWindow?.postMessage(
      {
        action: 'dashboardActions-enable',
        enableReports: hasSchedulePermission,
      },
      SISENSE_SERVER_PATH as string
    );
  };

  const renderDashboard = () => {
    if (!dashboardIdResponse?.survey_insights_id || !surveys.length) return;
    trackLoadingStart();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { SisenseFrame } = window['sisense.embed'];

    // Create an instance of SisenseFrame
    const sisenseFrame = new SisenseFrame({
      url: SISENSE_SERVER_PATH,
      dashboard: dashboardIdResponse?.survey_insights_id,
      settings: {
        showToolbar: false,
        showLeftPane: false,
        showRightPane: true,
      },
      // Existing iFrame DOM element
      element: iFrameRef.current,
      // Disable the autosave of user interactions with the dashboard
      // volatile: true,
    });
    sisenseFrameRef.current = sisenseFrame;

    // Calling render() will apply the above configuration to the existing iFrame element
    sisenseFrame.render();
    sisenseFrame.app.on('stateappchanged', trackLoadingStart);

    // When dashboard is loaded
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sisenseFrame.dashboard.on('dashboardloaded', (args: any) => {
      window.history.pushState(null, '', window.location.href);
      applySecurityFilters(args.dashboard.filters, sisenseFrame);
      enableDashboardActions();
      // Some delay to render actions widgets in firefox
      setTimeout(() => {
        enableDashboardActions();
      }, 500);
      setDashboardLoaded(true);
      trackLoadingEnd(args.dashboard.oid, args.dashboard.title);
    });
  };

  if (show && isSurveysLoaded && !surveys.length) return <EmptyState />;

  return (
    <Box sx={{ ...styles.container, ...(!show && { display: 'none' }) }}>
      {!dashboardLoaded && (
        <DashboardLoader
          message={
            <FormattedMessage
              id="analytics.loadingDashboard"
              defaultMessage="Loading your dashboard..."
            />
          }
        />
      )}
      <iframe
        ref={iFrameRef}
        data-hj-allow-iframe
        title="Sisense Embed"
        width="80%"
        height="100%"
        style={{
          border: 'none',
          ...(!dashboardLoaded && { display: 'none' }),
        }}
      />
      {hasSchedulePermission && showReports && show ? (
        <ReportsDrawer
          dashboardId={showReports}
          enabledFormats={['pdf']}
          onClose={() => {
            setShowReports(undefined);
          }}
          changelingMode={isChangeling}
        />
      ) : null}
    </Box>
  );
};

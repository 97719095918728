import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  SEARCH_DEBOUNCE_TIME_MS,
  type Segment,
  type UpdateSegment,
  actionMessages,
  useIsValidSegmentName,
  useUpdateSegment,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
  snackbar,
} from '@trustyou/ui';
import { debounce } from 'lodash';

import { messages } from '../../../../../constants/messages/segments';
import { SEGMENT_NAME_MAX_LENGTH } from '../../../../../constants/organization';
import { useDashboardDelayMessage } from '../../../../../hooks';
import styles from './styles';

type EditSegmentsProps = {
  onClose: () => void;
  onSegmentEdit: (segment: Segment) => void;
  segment: Segment;
};

export default function EditSegments({ segment, onSegmentEdit, onClose }: EditSegmentsProps) {
  const intl = useIntl();
  const dashboardDelayMessage = useDashboardDelayMessage();

  const [isChanging, setIsChanging] = useState(false);

  const validSegmentName = useIsValidSegmentName();
  const updateSegment = useUpdateSegment();

  const { control, handleSubmit, formState } = useForm<Partial<Segment>>({
    mode: 'onChange',
    defaultValues: segment,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheck = useCallback(
    debounce(async (value: string) => {
      if (!value || control._defaultValues.name === value.trim()) {
        setIsChanging(false);
        return;
      }
      const response = await validSegmentName.mutateAsync(value);
      setIsChanging(false);
      if (!response || response.valid) return;
      let message = '';
      if (response.error === 'matches_global_attribute_name') {
        message = intl.formatMessage(messages.nameReserved);
      } else if (response.error === 'matches_custom_attribute_name') {
        message = intl.formatMessage(messages.nameUsedInACustomAttribute);
      }
      control.setError('name', {
        type: 'custom',
        message: message || intl.formatMessage(messages.nameExist),
      });
    }, SEARCH_DEBOUNCE_TIME_MS),
    []
  );

  const onSubmit = (data: Partial<Segment>) => {
    updateSegment.mutate(
      {
        id: data.id as string,
        name: data.name as string,
      },
      {
        onSuccess: (data: UpdateSegment) => {
          onSegmentEdit({ ...segment, ...data });
          onClose();
          snackbar.success(
            intl.formatMessage(messages.changesSaved, { delay: dashboardDelayMessage })
          );
        },
      }
    );
  };

  return (
    <Dialog fullWidth maxWidth="xs" open onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="h6">{intl.formatMessage(messages.editSegmentFormHeader)}</DialogTitle>
        <DialogContent>
          <FormControl sx={styles.segmentNameForm}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: intl.formatMessage(messages.nameRequired),
                onChange: async (event) => {
                  if (event) {
                    setIsChanging(true);
                    debounceCheck(event.target.value);
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  sx={styles.nameField}
                  size="small"
                  label={intl.formatMessage(messages.nameOfSegment)}
                  id="segment-name"
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error?.message || intl.formatMessage(messages.nameHelperText)
                  }
                  type="text"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {validSegmentName.isPending && <CircularProgress size={20} />}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: SEGMENT_NAME_MAX_LENGTH,
                  }}
                />
              )}
            />
          </FormControl>
          <BackdropSpinner isLoading={updateSegment.isPending} />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-edit-segment" onClick={onClose} color="inherit">
            {intl.formatMessage(actionMessages.cancel)}
          </Button>
          <Button
            data-testid="accept-edit-segment"
            disabled={isChanging || !formState.isValid || !!Object.keys(formState.errors).length}
            type="submit"
            variant="contained"
          >
            {intl.formatMessage(actionMessages.save)}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

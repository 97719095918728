/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, StyledFontAwesomeIcon as Icon, Stack, Typography } from '@trustyou/ui';

import { ContentItem } from './components/content-item';
import { SurveyContentList } from './components/survey-contents-list';
import { SurveySectionList } from './components/survey-section-list';

import type { ContentQuestion_Input, Question_Output } from '../../../../client';
import type { Content } from '../../../../types/content';
import type {
  QuestionToSectionProps,
  SurveyQuestionnaireEditorContentOutput,
} from '../../../../types/survey';
import { filterChosenContents } from '../../../../utils/survey-questionnaire-editor';

export function EditorSubmitSectionContent({
  questions,
  sections,
}: {
  questions: Question_Output[];
  sections: Content[];
}) {
  const { control, setValue, watch, getValues, trigger } = useFormContext();
  const { append } = useFieldArray({
    control,
    name: 'submitSection',
  });
  const [fetchedQuestions, setFetchedQuestions] = useState<Content[]>(questions);
  const [fetchedSections, setFetchedSections] = useState<Content[]>(sections);
  //Content List Dialog
  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [contentRowSelectModel, setContentRowSelectModel] = useState<GridRowSelectionModel>([]);
  const [questionToSection, setQuestionToSection] = useState<QuestionToSectionProps | null>(null);
  //Section List Dialog
  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [sectionRowSelectModel, setSectionRowSelectModel] = useState<GridRowParams>();
  const [updateSectionIndex, setUpdateSectionIndex] = useState<number | null>(null);

  //User chosen list of contents
  const submitSection: SurveyQuestionnaireEditorContentOutput[] = watch('submitSection');

  useEffect(() => {
    setFetchedQuestions(questions);
    setFetchedSections(sections);

    //Set default submit section
    if (sections.length > 0 && questions.length > 0 && submitSection?.length === 0) {
      const filteredSubmitSection = sections.filter((section) => section.is_default === true);
      const submitSectionQuestions = questions
        .filter(
          (content) =>
            [
              'contact_info_name',
              'contact_info_email',
              'data_privacy_information',
              'terms_and_conditions',
            ].includes(content.handling_type) && content.is_default === true
        )
        .map((content: ContentQuestion_Input) => ({
          ...content,
          mandatory: true,
        }));

      const defaultSubmitSection = [
        {
          ...filteredSubmitSection[0],
          questions: [...submitSectionQuestions],
        },
      ];
      setValue('submitSection', defaultSubmitSection);
    }
  }, [questions, sections]);

  //Filtering the chosen contents from the list of all contents
  //TODO: Check why this called many times
  useEffect(() => {
    const filteredContents = filterChosenContents({
      allItems: questions,
      chosenItems: submitSection,
    });
    const filteredSections = filterChosenContents({
      allItems: sections,
      chosenItems: submitSection,
    });
    setFetchedQuestions(filteredContents);
    setFetchedSections(filteredSections);
  }, [questions, sections, submitSection]);

  const addQuestionToSection = ({ sectionId, questions = [] }: QuestionToSectionProps) => {
    const updatedContent = submitSection?.map((item) => {
      const currentQuestions = item.questions || [];
      if (item.id === sectionId) {
        return {
          ...item,
          questions: [...currentQuestions, ...questions],
        };
      }
      return item;
    });

    setValue('submitSection', updatedContent);
  };

  const addQuestion = () => {
    setOpenContentDialog(false);
    const chosenContentData = questions.filter((item) => contentRowSelectModel.includes(item.id));

    if (questionToSection) {
      addQuestionToSection({
        sectionId: questionToSection.sectionId,
        questions: chosenContentData,
      });
      setQuestionToSection(null);
    } else {
      append(chosenContentData);
    }
    setContentRowSelectModel([]);
  };

  const updateSection = () => {
    if (updateSectionIndex !== null) {
      const newData = [...submitSection];
      const originalItem = submitSection[updateSectionIndex];
      const originalQuestions = originalItem.questions;

      const updatedContent = {
        ...sectionRowSelectModel?.row,
        questions: originalQuestions,
      };
      newData[updateSectionIndex] = updatedContent;

      setValue('submitSection', newData);
      setUpdateSectionIndex(null);
      setOpenSectionDialog(false);
    } else {
      setOpenSectionDialog(false);
      if (sectionRowSelectModel?.row) {
        append(sectionRowSelectModel.row);
      }
    }
  };

  const deleteQuestionInSection = ({ sectionId, questionId }: QuestionToSectionProps) => {
    const updatedPageContent = submitSection.map((item: SurveyQuestionnaireEditorContentOutput) => {
      if (item.id === sectionId) {
        const updatedQuestions = item.questions.filter(
          (q: SurveyQuestionnaireEditorContentOutput) => q.id !== questionId
        );
        return {
          ...item,
          questions: updatedQuestions,
        };
      }
      return item;
    });

    setValue('submitSection', updatedPageContent);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const sectionIndex = submitSection.findIndex(
      (section) => section.id.toString() === source.droppableId
    );
    const questions = getValues(`submitSection[${sectionIndex}].questions`);

    const [movedQuestion] = questions.splice(source.index, 1);
    questions.splice(destination.index, 0, movedQuestion);

    setValue(`submitSection[${sectionIndex}].questions`, questions);
    trigger('submitSection');
  };

  return (
    <Stack>
      <Stack>
        <Typography variant="h6">
          <FormattedMessage
            id="survey.survey-editor.page.submit-section"
            defaultMessage="Submit section"
          />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage
            id="survey.survey-editor.submit-section.description"
            defaultMessage="Customize the Submit section of your questionnaire"
          />
        </Typography>
      </Stack>
      <SurveyContentList
        data={fetchedQuestions}
        open={openContentDialog}
        rowSelectionModel={contentRowSelectModel}
        onRowSelectionModelChange={setContentRowSelectModel}
        onCancel={() => setOpenContentDialog(false)}
        onSubmit={addQuestion}
      />
      <SurveySectionList
        data={fetchedSections}
        open={openSectionDialog}
        onRowClick={setSectionRowSelectModel}
        onCancel={() => setOpenSectionDialog(false)}
        onSubmit={updateSection}
      />
      <Stack spacing={2} mt={4}>
        {submitSection &&
          submitSection?.map((content, index) => (
            <Stack spacing={2} key={content.id}>
              <ContentItem
                content={content}
                type={content.type}
                isSubmitSection
                onEditSection={() => {
                  setOpenSectionDialog(true);
                  setUpdateSectionIndex(index);
                }}
              >
                {content.type && content.type === 'section' && (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={content.id.toString()} type="section-questions">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {content.questions?.map(
                            (
                              question: SurveyQuestionnaireEditorContentOutput,
                              questionIndex: number
                            ) => (
                              <Draggable
                                key={question.id}
                                draggableId={question.id.toString()}
                                index={questionIndex}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ContentItem
                                      content={question}
                                      isQuestionInsideSection
                                      onDelete={() =>
                                        deleteQuestionInSection({
                                          sectionId: content.id,
                                          questionId: question.id,
                                        })
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        </div>
                      )}
                    </Droppable>
                    <Stack sx={{ alignItems: 'center', marginBlock: 2 }}>
                      <Button
                        variant="text"
                        startIcon={<Icon icon={faPlus} />}
                        onClick={() => {
                          setQuestionToSection({ sectionId: content.id });
                          setOpenContentDialog(true);
                        }}
                      >
                        <FormattedMessage id="survey.common.content" defaultMessage="Content" />
                      </Button>
                    </Stack>
                  </DragDropContext>
                )}
              </ContentItem>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}

import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import { stringToDateAndTime } from '@trustyou/shared';
import {
  Button,
  ComposableDrawerWithStickyFooter,
  FormControl,
  Stack,
  TextField,
  Typography,
  snackbar,
} from '@trustyou/ui';
import { DateTime } from 'luxon';

import { CancelEditReport } from './cancel-edit-report';
import { DeleteReport } from './delete-report';

import { commonMessages } from '../../constants/messages';
import { reportValidationSchema } from '../../constants/report-validation-schema';
import { useReportLastStatus, useUpdateReport } from '../../service/hooks';
import type { ReportValidationSchema, ScheduledReport } from '../../types/reports';
import { FormControlLabel } from '../form-control-label/form-control-label';
import { FrequencySelector } from '../frequency-selector/frequency-selector';
import { RecipientSelector } from '../recipient-selector/recipient-selector';
import styles from './styles';

type Props = {
  report: ScheduledReport;
  onClose: () => void;
};

export function EditReportForm({ report, onClose }: Props) {
  const intl = useIntl();
  const { data: reportStatus } = useReportLastStatus(report.id);
  const lastSent = reportStatus?.status === 'sent' && stringToDateAndTime(reportStatus.updated_at);

  const updateReport = useUpdateReport();

  const emails = (report.emails || '').split(',');
  const recipients = report.user_ids.split(',').map((id, index) => ({
    id,
    email: emails[index],
    name: '',
  }));

  const defaultValues = {
    name: report.report_name,
    recipients,
    frequency: report.period,
    timeOfDay: DateTime.fromObject(
      {
        hour: report.hour,
        minute: 0,
        second: 0,
        millisecond: 0,
      },
      { zone: report.timezone }
    ).toString(),
    dayOfMonth: report.day || reportValidationSchema.getDefault().dayOfMonth,
    dayOfWeek: report.day_of_week || reportValidationSchema.getDefault().dayOfWeek,
  };

  const { register, formState, control, getValues } = useForm<ReportValidationSchema>({
    mode: 'onChange',
    resolver: yupResolver(reportValidationSchema),
    defaultValues,
  });

  const onSubmit = () => {
    const values = getValues();
    const timeOfDay = DateTime.fromISO(values.timeOfDay as string);
    const updateData = {
      ...report,
      user_ids: (values.recipients || []).map((recipient) => recipient.id).join(','),
      report_name: values.name,
      hour: timeOfDay.hour,
      period: values.frequency,
      day_of_week: values.frequency === 'weekly' ? values.dayOfWeek || 0 : null,
      day: values.frequency === 'monthly' ? values.dayOfMonth || 1 : null,
      timezone: timeOfDay.zone.name,
    };
    delete updateData.emails;
    updateReport.mutate(updateData, {
      onSuccess: () => {
        snackbar.success(intl.formatMessage(commonMessages.editReportSuccess));
        onClose();
      },
    });
  };

  return (
    <>
      <ComposableDrawerWithStickyFooter.Content sx={styles.formContent}>
        <Stack gap={5}>
          <Stack gap={3}>
            <FormControl>
              <FormControlLabel required>
                {intl.formatMessage(commonMessages.nameYourReport)}
              </FormControlLabel>
              <TextField
                size="small"
                placeholder={intl.formatMessage(commonMessages.reportNamePlaceHolder)}
                id="report-name"
                {...register('name')}
                error={!!formState.errors.name}
                helperText={
                  !!formState.errors.name && intl.formatMessage(commonMessages.reportNameError)
                }
                type="text"
                variant="outlined"
              />
            </FormControl>
            <RecipientSelector control={control} />
          </Stack>
          <Stack gap={1}>
            <Typography variant="subtitle1">
              {intl.formatMessage(commonMessages.frequency)}
            </Typography>
            <FrequencySelector control={control} />
          </Stack>
          {lastSent && (
            <Stack gap={1}>
              <Typography variant="subtitle1">
                {intl.formatMessage(commonMessages.lastSent)}
              </Typography>
              <Typography variant="body1">{lastSent}</Typography>
            </Stack>
          )}
        </Stack>
      </ComposableDrawerWithStickyFooter.Content>
      <ComposableDrawerWithStickyFooter.Footer sx={styles.formFooter}>
        <DeleteReport reportId={report.id} onDeleted={onClose} />
        <CancelEditReport isDirty={formState.isDirty} onCancel={onClose} />
        <Button
          onClick={onSubmit}
          variant="contained"
          disabled={!formState.isDirty || !!Object.keys(formState.errors).length}
        >
          {intl.formatMessage(commonMessages.save)}
        </Button>
      </ComposableDrawerWithStickyFooter.Footer>
    </>
  );
}

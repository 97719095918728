/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { DateRange } from '@mui/x-date-pickers-pro';
import { useEntityCount, useInboxStore } from '@trustyou/shared';
import { Box, Divider, FormControlLabel, Stack, Switch, Typography, styled } from '@trustyou/ui';
import type { Dayjs } from 'dayjs';

import { NewViewButton } from './new-view-button';

import { type FiltersForm, FiltersFormFields } from '../../types';
import { getDefaultValueForTimeRange } from '../../utils/helpers';
import { CustomDateRangeDialog } from '../custom-date-range-dialog';
import { DATE_FORMAT } from '../custom-date-range-dialog/custom-date-range-picker';
import {
  DropdownLanguage,
  DropdownScore,
  DropdownSource,
  DropdownStatus,
  DropdownTimeRange,
} from '../dropdowns/filters-dropdowns';
import { CategoryFilter } from '../filters/categories';
import { EntityFilter } from '../filters/entities/entity-filter';

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginInline: `${theme.spacing(-2)} !important`,
}));

export type FiltersProps = {
  onClose?: () => void;
  isBeingUsedInReport?: boolean;
};

export const DropdownFilters = memo(() => {
  const currentView = useInboxStore((state) => state.currentView);
  const { setValue, getValues } = useFormContext<FiltersForm>();

  const [isTimeRangeDialogOpen, setIsTimeRangeDialogOpen] = useState(false);
  const openTimeRangeDialog = () => setIsTimeRangeDialogOpen(true);
  const closeTimeRangeDialog = () => setIsTimeRangeDialogOpen(false);

  const handleSubmitDate = useCallback((range: DateRange<Dayjs>) => {
    const [start, end] = range;
    const formattedRange = `${start?.format(DATE_FORMAT)} - ${end?.format(DATE_FORMAT)}`;
    setValue(
      FiltersFormFields.Rangename,
      getDefaultValueForTimeRange(currentView, formattedRange),
      {
        shouldDirty: true,
      }
    );
    closeTimeRangeDialog();
  }, []);

  return (
    <Stack spacing={3} sx={{ paddingBlock: 1 }}>
      <DropdownStatus initialValue={currentView.filters?.status as string[]} />
      <DropdownTimeRange
        openTimeRangeDialog={openTimeRangeDialog}
        initialValue={getDefaultValueForTimeRange(currentView, getValues().rangename)}
      />
      <CustomDateRangeDialog
        isOpen={isTimeRangeDialogOpen}
        onClose={closeTimeRangeDialog}
        onSubmit={handleSubmitDate}
        initialValue={currentView.filters?.timerange ?? undefined}
      />
      <DropdownScore
        fieldName={FiltersFormFields.Score}
        initialValue={currentView.filters?.score ?? []}
        size="small"
        fullWidth
      />
      <DropdownSource
        fieldName={FiltersFormFields.Source}
        initialValue={currentView.filters?.source ?? []}
        size="small"
        fullWidth
      />
      <DropdownLanguage
        fieldName={FiltersFormFields.Language}
        initialValue={currentView.filters?.language ?? ['all']}
        size="small"
        fullWidth
      />
      {/* TODO: Wait for product to decide make this filter visible. */}
      {/* <DropdownSurveyName
        fieldName={FiltersFormFields.SurveyName}
        initialValue={currentView.filters?.survey_name ?? []}
        size="small"
        fullWidth
      /> */}
    </Stack>
  );
});

export const CategoryAndEntityFilters = memo(() => {
  const { data: entityCount = 0 } = useEntityCount();
  const currentView = useInboxStore((state) => state.currentView);

  return (
    <Stack spacing={3} sx={{ paddingBlock: 1 }}>
      <CategoryFilter
        initial={{
          subcategories: currentView.filters?.sema_subcategories,
          categories: currentView.filters?.sema_categories,
        }}
      />
      {entityCount > 1 && (
        <>
          <StyledDivider />
          <EntityFilter />
        </>
      )}
    </Stack>
  );
});

export const ShowFormControlLabel = memo(() => {
  const currentView = useInboxStore((state) => state.currentView);
  const { register } = useFormContext<FiltersForm>();
  return (
    <FormControlLabel
      control={
        <Switch
          {...register(FiltersFormFields.Respondable)}
          defaultChecked={currentView.filters?.respondable ?? undefined}
        />
      }
      label={
        <>
          <Typography>
            <FormattedMessage
              id="inbox.filter.respondable.label"
              defaultMessage="Show answerable reviews only"
            />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage
              id="inbox.filter.respondable.description"
              defaultMessage="Excludes reviews that sources do not allow responses to, such as reviews without text"
            />
          </Typography>
        </>
      }
      labelPlacement="start"
      componentsProps={{ typography: { variant: 'body2' } }}
      sx={{
        ml: 0,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'start',
      }}
      data-gtm-id="inbox_filters_only_show_respondable_reviews_switch"
    />
  );
});

export const FiltersTabContent = memo(({ onClose = () => null }: FiltersProps) => {
  const { reset, clearErrors } = useFormContext<FiltersForm>();

  useEffect(() => {
    return () => {
      reset();
      clearErrors();
    };
  }, []);

  return (
    <Stack spacing={3} sx={{ paddingBlock: 1 }}>
      <DropdownFilters />
      <CategoryAndEntityFilters />
      <StyledDivider />
      <ShowFormControlLabel />
      <StyledDivider />
      <Box sx={{ alignSelf: 'center' }}>
        <NewViewButton onCancel={onClose} />
      </Box>
    </Stack>
  );
});
